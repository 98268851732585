import { Injectable } from '@angular/core';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { AlertsApiService } from './api.service';
import { of } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IAlertsState } from './state';

@Injectable({
  providedIn: 'root'
})
export class AlertsAsync {
  constructor(
    private lux: Lux<{ ptAlertsState: IAlertsState}>,
    private alertsService: AlertsApiService,
  ) { }

  getPTEntityAlerts(req:any, limit: number, offset: number) {
      return of(req).pipe (
      first(),
      tap(() =>
        this.lux.set(state => state.ptAlertsState, { isFetchingAlerts: true, request: req })),
      mergeMap(x => this.alertsService.getPTEntityAlerts(req, limit, offset)),
      tap(results => {
        this.lux.set(state => state.ptAlertsState, { alerts: results, isFetchingAlerts: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map((err:any) => err.errorMessage).join('\n');
        }
        this.lux.set(state => state.ptAlertsState, { errors: errorMessage, isFetchingAlerts: false });
        return of(errorMessage);
      })
    );
  }

  getPTGeneralAlerts(req:any, limit: number, offset: number) {
      return of(req).pipe (
      first(),
      tap(() =>
        this.lux.set(state => state.ptAlertsState, { isFetchingAlerts: true, request: req })),
      mergeMap(x => this.alertsService.getPTGeneralAlerts(req, limit, offset)),
      tap(results => {
        this.lux.set(state => state.ptAlertsState, { generalAlerts: results, isFetchingAlerts: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map((err:any) => err.errorMessage).join('\n');
        }
        this.lux.set(state => state.ptAlertsState, { errors: errorMessage, isFetchingAlerts: false });
        return of(errorMessage);
      })
    );
  }
}
