import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { Observable } from 'rxjs';
import { CreateManageUserAsync } from '../../store/user-admin/async';
import { getErrors, isUserGettingCreated, isUserCreated } from '../../store/user-admin/selector';
import { ICreateManageUserState } from '../../store/user-admin/state';
import { matchFields } from '../../utils/validations/field-rules';

@Component({
  selector: 'pt-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {
  luxId = 'PT-create-user'+this.constructor.name;

  createUserForm: FormGroup = this.fb.group({});
  getErrors$:Observable<string> = this.lux.get(getErrors, this.luxId);
  isUserGettingCreated$:Observable<boolean> = this.lux.get(isUserGettingCreated, this.luxId);
  isUserCreated$:Observable<boolean> = this.lux.get(isUserCreated, this.luxId);
  isUserCreationInProcess: boolean = false;
  createUserErrors: string = '';
  isUserGotCreated: boolean = false;
  constructor(private fb: FormBuilder, private lux: Lux<{createManageUserState: ICreateManageUserState}>, private createManageUserAsync: CreateManageUserAsync) { }

  ngOnDestroy(): void {
    this.lux.set(state => state.createManageUserState, {errors: '', userCreated: false});
    this.lux.destroy(this.luxId);
  }

  ngOnInit(): void {
    this.getErrors$.subscribe(errors => this.createUserErrors = errors);
    this.isUserGettingCreated$.subscribe(isUserCreationInProcess => this.isUserCreationInProcess = isUserCreationInProcess);
    this.isUserCreated$.subscribe(isUserCreated => this.isUserGotCreated = isUserCreated);
    this.setCreateUserForm();
    this.createUserForm.valueChanges.subscribe(() => this.resetErrors());
  }

  setCreateUserForm() {
    this.createUserForm = this.fb.group({
      userIdentifier: ['', [Validators.required, Validators.pattern('^[0-9a-zA-Z]*$'), Validators.minLength(5), Validators.maxLength(25)]],
      lastName: ['', [Validators.pattern('^[a-zA-Z]+( +[a-zA-Z]+)*$'), Validators.required, Validators.maxLength(50)]],
      firstName: ['', [Validators.pattern('^[a-zA-Z]+( +[a-zA-Z]+)*$'), Validators.required, Validators.maxLength(50)]],
      companyName: ['', [Validators.pattern('^[a-zA-Z]+( +[a-zA-Z]+)*$'), Validators.required, Validators.maxLength(150)]],
      emailAddress: ['', [matchFields('emailAddress', 'confirmEmail', 'emailMissMatch'), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required, Validators.maxLength(100)]],
      confirmEmail: ['', [matchFields('emailAddress', 'confirmEmail', 'emailMissMatch'), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required, Validators.maxLength(100)]]
    });
    this.resetErrors();
  }

  createUser(){
    if(this.createUserForm.valid) {
      delete this.createUserForm.value.confirmEmail;
      const createUserReq = {
        user: {...this.createUserForm.value}
      }
      this.lux.set(state => state.createManageUserState.createUserReq, createUserReq);
      this.createManageUserAsync.createUser().subscribe();
    }
  }

  resetErrors() {
    this.lux.set(state => state.createManageUserState, { isUserGettingCreated: false, errors: '', userCreated: false})
  }

}
