import { Component, OnDestroy } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { ViewManageUserAuthsAsync } from '../../store/viewManageUserAuths/async';
import { IViewManageUserAuthsState } from '../../store/viewManageUserAuths/state';
import { Observable } from 'rxjs';
import { getUserAuthsErrors } from '../../store/viewManageUserAuths/selector';

@Component({
  selector: 'pt-authorizations',
  templateUrl: './authorizations.component.html',
  styleUrl: './authorizations.component.scss'
})
export class AuthorizationsComponent implements OnDestroy {
  luxId = this.constructor.name+'PT-PROFILE-COMPONENT';
  authorizationRecords: any[] = [];
  tableHeaders: string[] = ['TIN', 'agencyName', 'NPN', 'agentName', 'groupStatement', 'individualStatement', 'groupBusiness', 'individualBusiness', 'documents'];
  offset = 0;
  pageSize: number = 100;
  pageIndex: number = 0;
  totalAuths: number = 0;
  getErrors$: Observable<string> = this.lux.get(getUserAuthsErrors, this.luxId);
  fetchingUserAuthsErrors: string = '';

  constructor(
    private lux: Lux<{ viewManageUserAuthsState: IViewManageUserAuthsState }>,
    private viewManageUserAuthService :ViewManageUserAuthsAsync
  ) {
    this.getErrors$.subscribe(errs => this.fetchingUserAuthsErrors = errs);

    this.getLoggedInUserAuths({'previousPageIndex':0,'pageIndex':0,'length':0});
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  getLoggedInUserAuths(page: any) {
    if (page.pageSize) { this.pageSize = page.pageSize; }
    this.pageIndex = page && page.pageIndex ? Number(page.pageIndex) : 0;
    if (isNaN(this.pageIndex)) {
      return;
    }
    this.offset = this.pageIndex === 0 ? 0 : (this.pageIndex * this.pageSize);
    const REQUEST = {
      pagination: {offset: this.offset, limit: this.pageSize},
      sort: [{order: "DESC",fieldName: "TIN"}]
    };
    this.viewManageUserAuthService.getUserAuths(REQUEST).subscribe(auths => {
      this.totalAuths = auths.pagination.totalRecords;
      this.pageIndex = auths.pagination.offset;
      this.authorizationRecords = auths.userAuthorizations;
    });
  }
}
