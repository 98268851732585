import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppConfig } from './shared/app.config';
import {
  BasicAuthConfig,
  AuthInterceptor,
  Lux,
  ErrorMessageMap,
  defaultErrorMessageMap,
  LuxHP
} from '@roosevelt/common-ui-lib/core';
import {
  NavStackModule
} from '@roosevelt/common-ui-lib/nav-stack';
import {
  DialogModule
} from '@roosevelt/common-ui-lib/dialog';
import {
  ButtonModule
} from '@roosevelt/common-ui-lib/button';
import {
  BannerModule
} from '@roosevelt/common-ui-lib/banner';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './login/login.module';
import { PTCommissionStatementsModule } from './commission-statements/commission-statements.module';
import { HelpModule } from './help/help.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PTAuthInterceptor } from './shared/interceptors/auth-interceptor.service';
import OktaAuth from '@okta/okta-auth-js';
import {SupportingDocumentsModule} from './supporting-documents/supporting-documents.module';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { ProducersModule } from './producers/producers.module';
import { ProfileModule } from './profile/profile.module';
import { UserAdminModule } from './user-admin/user-admin.module';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { ViewManageUserAuthsModule } from './view-manage-user-auths/view-manage-user-auths.module';
import { IViewManageUserAuthsState, initialViewManageUserState } from './store/viewManageUserAuths/state';
import { PRODUCER_STATE_PREFIX } from '@roosevelt/producer-lib';
import { PRODUCER_BOB_STATE_PREFIX } from '@roosevelt/producer-toolkit-lib';
import { NavigateToBmtComponent } from './navigate-to-bmt/navigate-to-bmt.component';
import { RegistrationModule } from './registration/registration.module';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';

@NgModule({
  declarations: [AppComponent, NotAuthorizedComponent, NavigateToBmtComponent],
  imports: [
    AppRoutingModule,
    BannerModule,
    BrowserModule,
    ButtonModule,
    LoginModule,
    PTCommissionStatementsModule,
    NavStackModule,
    HelpModule,
    DialogModule,
    SupportingDocumentsModule,
    LoaderModule,
    ProducersModule,
    PaginatorModule,
    ProfileModule,
    UserAdminModule,
    ViewManageUserAuthsModule,
    RegistrationModule
  ],
  providers: [
    Lux,
    LuxHP,
    {
      provide: AppConfig,
      useExisting: BasicAuthConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      deps: [BasicAuthConfig, OktaAuth],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PTAuthInterceptor,
      deps: [BasicAuthConfig],
      multi: true
    },
    {
      provide: ErrorMessageMap,
      useValue: {
        ...defaultErrorMessageMap,
        emailMissMatch: () => `Emails don't match`,
        zMinLength:() => 'Value must be at least 12 characters in length',
        maxLength: () => 'Value must be at most 25 characters in length.',
        lowercase: () => 'Must contain a lowercase character',
        uppercase: () => 'Must contain an uppercase character',
        special: () => 'Must contain one of the follow $@!%?&_# ',
        number: () => 'Must contain a number',
        mismatch: () => 'Passwords do not match',
        userName: () => `Should begin with 2 alpha characters and must be between 5-25 characters long and can contain only the following special characters -.@_`,
        password: () => 'Password should be 12-25 characters long and should contain at least one of each - numeric, upper case, lower case and special characters',
      }
    },
    {
      provide: PRODUCER_STATE_PREFIX,
      useValue: 'producer-toolkit-'
    },
    {
      provide: PRODUCER_BOB_STATE_PREFIX,
      useValue: 'producer-toolkit-'
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(lux: Lux<{ viewManageUserAuthsState: IViewManageUserAuthsState }>) {
    const viewManageUserAuthsState = JSON.parse(sessionStorage.getItem('viewManageUserAuthsState') as any);
    lux.register('viewManageUserAuthsState', viewManageUserAuthsState ? viewManageUserAuthsState : initialViewManageUserState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('viewManageUserAuthsState', JSON.stringify(state.viewManageUserAuthsState)));
  }
}
