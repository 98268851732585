import { IAlertsState, IGeneralAlerts, IPTEntityAlertsRequest, IPTGeneralAlertsRequest } from './state';
import { IPagination } from '../user-admin/state';

export const getPTEntityAlerts = (state: { ptAlertsState: IAlertsState }): IGeneralAlerts[] => state.ptAlertsState.alerts.entityAlerts;
export const getPTGeneralAlerts = (state: { ptAlertsState: IAlertsState }): IGeneralAlerts[] => state.ptAlertsState.generalAlerts.generalAlerts;
export const getPTAlertsPagination = (state: { ptAlertsState: IAlertsState }): IPagination => state.ptAlertsState.alerts.pagination;
export const getPTGeneralAlertsPagination = (state: { ptAlertsState: IAlertsState }): IPagination => state.ptAlertsState.generalAlerts.pagination;
export const getPTAlertsRequest = (state: { ptAlertsState: IAlertsState }): IPTEntityAlertsRequest => state.ptAlertsState.request;
export const getPTGeneralAlertsRequest = (state: { ptAlertsState: IAlertsState }): IPTGeneralAlertsRequest => state.ptAlertsState.generalRequest;

export const alertErrors = (state: { ptAlertsState: IAlertsState }): string => state.ptAlertsState.errors;
