import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private fb: FormBuilder, private route: Router,
              private activatedRoute: ActivatedRoute) {
  }

  registerForm: FormGroup = this.fb.group({});
  agencyLevel: boolean = false;
  independentAgent: boolean = false;
  error: string = '';
  @Output() registerSubmit: EventEmitter<any> = new EventEmitter();


  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.agencyLevel = params.lvl === 'agencyLevel';
      this.independentAgent = params.lvl === 'independentAgent';
    });
    if (!(this.agencyLevel || this.independentAgent)) {
      this.error = 'Please use the link provided in your registration email. Please try again or retype the webpage address.';
    }
    this.registerForm = this.fb.group({
      authorizationCode: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.maxLength(100), Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]],
      agencyTin: [{ value: '', disabled: !this.agencyLevel }, [Validators.required, Validators.minLength(9)]],
      agentSsn: [{ value: '', disabled: !this.independentAgent }, [Validators.required, Validators.minLength(9)]]
    });
  }

}
