<div>
  <h4 style="padding: 0px 10px;">Users List</h4>
  <rs-loader *ngIf="isFetchingUserList || isUserDetailsUpdating"></rs-loader>
  <rs-notification type="error" [visible]="fetchingUsersListErrors.length > 0" closeable (visibleChange)="fetchingUsersListErrors = ''">{{fetchingUsersListErrors}}</rs-notification>
  <rs-notification type="error" [visible]="errorUpdatingUserDetails.length > 0" closeable (visibleChange)="errorUpdatingUserDetails = ''" >
    <span>{{errorUpdatingUserDetails}}</span>
  </rs-notification>
  <table rs-table [dataSource]="usersData"  style="width:100%;">

    <ng-container rsColumnDef="userIdentifier">
      <th rs-header-cell *rsHeaderCellDef (click)="sortTheUserList('userIdentifier')">User Name
        <rs-icon style="font-size: 14px" *ngIf="sortKey === 'userIdentifier'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
      </th>
      <td rs-cell *rsCellDef="let element">{{element.userIdentifier}}</td>
    </ng-container>

    <ng-container rsColumnDef="type">
      <th rs-header-cell *rsHeaderCellDef (click)="sortTheUserList('userType')">User Type
        <rs-icon style="font-size: 14px" *ngIf="sortKey === 'userType'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
      </th>
      <td rs-cell *rsCellDef="let element">{{element.userType | titlecase }}</td>
    </ng-container>

    <ng-container rsColumnDef="manage">
      <th rs-header-cell *rsHeaderCellDef></th>
      <td rs-cell *rsCellDef="let element" style="color: #41a928; cursor: pointer;" (click)="manageUser(element)">Manage User</td>
    </ng-container>

    <ng-container rsColumnDef="authorize">
      <th rs-header-cell *rsHeaderCellDef></th>
      <td rs-cell *rsCellDef="let element" style="color: #41a928;cursor: pointer;" (click)="goToUserAuths(element.userIdentifier, element.userType)">Authorizations</td>
    </ng-container>

    <ng-container rsColumnDef="status">
      <th rs-header-cell *rsHeaderCellDef (click)="sortTheUserList('userStatus')">User Status
        <rs-icon style="font-size: 14px" *ngIf="sortKey === 'userStatus'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
      </th>
      <td rs-cell *rsCellDef="let element">
        {{element.userStatus}}
        <button rs-raised-button color="primary" *ngIf="element.userStatus === 'Disabled'" (click)="updateUserInfo(element.userIdentifier, {status: 'Active'})">ENABLE</button>
        <button rs-raised-button color="secondary" *ngIf="element.userStatus === 'Enabled'"  (click)="updateUserInfo(element.userIdentifier, {status: 'Inactive'})">DISABLE</button>
      </td>
    </ng-container>

    <tr rs-header-row *rsHeaderRowDef="userListColumns"></tr>
    <tr rs-row *rsRowDef="let row; columns: userListColumns"></tr>
    <tr class="rs-row" *rsNoDataRow>
      <td colspan="5" style="text-align: center; height: 150px" class="rs-cell">
        <p>No users were created by you</p>
      </td>
      </tr>
  </table>
  <div style="padding:15px 0; position: relative;">
    <ng-container *ngIf="usersData.length > 0">
      <rs-paginator [pageIndex]="currentPageIndex" [length]="totalUsers" [pageSize]="pageSize"
          [pageSizeOptions]="[10,100]" (page)="getUsersList($event)" showFirstLastButtons>
      </rs-paginator>
    </ng-container>
  </div>
</div>

<!-- Confirmation popup -->
<rs-dialog [visible]="manageUserDialog" heading="Update User Info" maxWidth="460px" (visibleChange)="resetUpdateUserForm()">
  <rs-loader *ngIf="isFetchingUserDetails || isUserDetailsUpdating"></rs-loader>
  <rs-notification type="error" [visible]="errorFetchingUserDetails.length > 0" closeable (visibleChange)="errorFetchingUserDetails = ''" >
    <span>{{errorFetchingUserDetails}}</span>
  </rs-notification>
  <rs-notification type="error" [visible]="errorUpdatingUserDetails.length > 0" closeable (visibleChange)="errorUpdatingUserDetails = ''" >
    <span>{{errorUpdatingUserDetails}}</span>
  </rs-notification>
  <ng-container *ngIf="!isUserDetailsUpdated">
    <div class="update-user-form">
      <form [formGroup]="updateUserForm" (ngSubmit)="updateModifiedUserDetails()">
        <div style="width: 350px;">
          <rs-input formControlName="userIdentifier" label="User Name:"></rs-input>
          <rs-input validation="eager" formControlName="firstName" label="First Name:"></rs-input>
          <rs-input validation="eager" formControlName="lastName" label="Last Name:"></rs-input>
          <rs-input validation="eager" formControlName="companyName" label="Company Name:"></rs-input>
          <rs-input validation="eager" type="email" formControlName="emailAddress" label="Email Address:"></rs-input>
        </div>
        <div style="padding-top: 20px;justify-content: space-between;display: flex;">
          <button style="width: auto;" type="reset" rs-button color="secondary" (click)="resetUpdateUserForm()">Cancel</button>
          <button style="width: auto;" [disabled]="!updateUserForm.valid || !isUserInfoChanged" type="submit" rs-raised-button color="primary">Update</button>
        </div>
      </form>
    </div>
  </ng-container>
  <div class="status-update" *ngIf="isUserDetailsUpdated">
    <rs-notification type="success" [visible]="true">
      <span>User info has been updated</span>
    </rs-notification>
    <button style="width: auto;" (click)="resetUpdateUserForm()" rs-button color="secondary">close</button>
  </div>
</rs-dialog>
