import { getPageIndexTracker, getUserDetails, getUserListReq, isFetchingUserDetails, errorFetchingUserDetails, isUserDetailsUpdating, errorUpdatingUserDetails, isUserDetailsUpdated } from './../../store/user-admin/selector';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, startWith } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { CreateManageUserAsync } from '../../store/user-admin/async';
import { IUserListRes, ICreateManageUserState, IUserListObj, IUserListReq, initialGetUsersReq, IPageIndex, IUserDetails, initialUserDetails } from '../../store/user-admin/state';
import { getUserslistErrors, isFetchingUserLists, getUserLists } from '../../store/user-admin/selector';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'pt-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit, OnDestroy {
  luxId = 'PT-manage-user' + this.constructor.name;

  getUserslistErrors$: Observable<string> = this.lux.get(getUserslistErrors, this.luxId);
  isFetchingUserLists$: Observable<boolean> = this.lux.get(isFetchingUserLists, this.luxId);
  getUserLists$: Observable<IUserListRes> = this.lux.get(getUserLists, this.luxId);
  getUserListsReq$: Observable<IUserListReq> = this.lux.get(getUserListReq, this.luxId);
  getPageIndexTracker$: Observable<IPageIndex> = this.lux.get(getPageIndexTracker, this.luxId);
  getUserDetails$: Observable<IUserDetails> = this.lux.get(getUserDetails, this.luxId);
  isFetchingUserDetails$: Observable<boolean> = this.lux.get(isFetchingUserDetails, this.luxId);
  errorFetchingUserDetails$: Observable<string> = this.lux.get(errorFetchingUserDetails, this.luxId);
  isUserDetailsUpdating$: Observable<boolean> = this.lux.get(isUserDetailsUpdating, this.luxId);
  errorUpdatingUserDetails$: Observable<string> = this.lux.get(errorUpdatingUserDetails, this.luxId);
  isUserDetailsUpdated$: Observable<boolean> = this.lux.get(isUserDetailsUpdated, this.luxId);
  usersData: IUserListObj[] = [];
  previousPageIndex = 0;
  currentPageIndex = 0;
  pageSize = 100;
  isFetchingUserList: boolean = false;
  fetchingUsersListErrors: string = '';
  totalUsers = 0;
  currentPage = 0;
  getUsersReq: IUserListReq = initialGetUsersReq;
  sortKey: string = 'userIdentifier';
  orderOfSort: { [sortKey: string]: string } = {userIdentifier: 'ASC'};
  userListColumns: string[] = ['userIdentifier', 'type', 'manage', 'authorize', 'status'];
  updateUserForm: FormGroup = this.fb.group({});
  manageUserDialog: boolean = false;
  userDetails: IUserDetails = initialUserDetails;
  isFetchingUserDetails: boolean = false;
  errorFetchingUserDetails: string = '';
  formSnapShot: any;
  isUserInfoChanged: boolean = false;
  isUserDetailsUpdating: boolean = false;
  errorUpdatingUserDetails: string = '';
  isUserDetailsUpdated: boolean = false;

  constructor(
    private lux: Lux<{ createManageUserState: ICreateManageUserState }>,
    private createManageUserAsync: CreateManageUserAsync,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  ngOnInit(): void {
    this.isFetchingUserLists$.subscribe(isFetching => this.isFetchingUserList = isFetching);
    this.getUserLists$.subscribe(usersResponse => {
      this.totalUsers = usersResponse.pagination.totalRecords as number;
      this.usersData = usersResponse.users;
    });
    this.getUserListsReq$.subscribe(req => {
      this.getUsersReq = req;
      this.pageSize = req.pagination.limit
    });
    this.getPageIndexTracker$.subscribe(index => {
      this.currentPageIndex = index.currentPageIndex;
      this.previousPageIndex = index.previousPageIndex;
    });
    this.getUserslistErrors$.subscribe(errors => this.fetchingUsersListErrors = errors);
    this.getUsersList({'previousPageIndex':this.previousPageIndex,'pageIndex':this.currentPageIndex,'length':this.pageSize});
    this.updateUserForm = this.fb.group({
      userIdentifier: [{ value: '', disabled: true }],
      lastName: ['', [Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.required, Validators.maxLength(50)]],
      firstName: ['', [Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.required, Validators.maxLength(50)]],
      companyName: ['', [Validators.required, Validators.maxLength(150)]],
      emailAddress: [ '', [Validators.maxLength(100), Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'), Validators.required]]
    });
    this.getUserDetails$.subscribe(details => {
      this.userDetails = details;
      let FORM_VALUES = {
        lastName: details.lastName,
        firstName:  details.firstName,
        companyName:  details.companyName,
        emailAddress: details.emailAddress
      }
      this.formSnapShot = JSON.stringify(FORM_VALUES);
      FORM_VALUES['userIdentifier'] = details.userIdentifier;
      this.updateUserForm.patchValue(FORM_VALUES);
    });
    this.updateUserForm.valueChanges.pipe(startWith(this.updateUserForm.value)).subscribe(value => {
      if(this.updateUserForm.valid) this.isUserInfoChanged = this.formSnapShot !== JSON.stringify(value);
    });
    this.isFetchingUserDetails$.subscribe(res => this.isFetchingUserDetails = res);
    this.errorFetchingUserDetails$.subscribe(res => this.errorFetchingUserDetails = res);
    this.isUserDetailsUpdating$.subscribe(res => this.isUserDetailsUpdating = res);
    this.errorUpdatingUserDetails$.subscribe(res => this.errorUpdatingUserDetails = res);
    this.isUserDetailsUpdated$.subscribe(res => this.isUserDetailsUpdated = res);
  }

  getUsersList(page: any) {
    if (page.pageSize) { this.pageSize = page.pageSize; }
    this.currentPageIndex = page && page.pageIndex ? Number(page.pageIndex) : 0;
    this.previousPageIndex = page && page.previousPageIndex ? Number(page.previousPageIndex) : 0;
    if (isNaN(this.currentPageIndex)) {
      return;
    }
    this.getUsersReq = {
      pagination: {
        offset: this.currentPageIndex === 0 ? 0 : (this.currentPageIndex * this.pageSize),
        limit: this.pageSize,
        totalRecords: 0
      },
      sort: [{
        order: this.orderOfSort[this.sortKey],
        fieldName: 'users.' + this.sortKey
      }],
      searchType: 'children'
    };

    this.getUsersService();
  }

  sortTheUserList(sortKey: string) {
    this.sortKey = sortKey;
    this.orderOfSort[sortKey] = this.orderOfSort[sortKey] ? (this.orderOfSort[sortKey] === 'ASC' ? 'DESC' : 'ASC') : 'DESC';
    this.getUsersReq = {
      ...this.getUsersReq,
      sort: [{
        fieldName: 'users.' + this.sortKey,
        order: this.orderOfSort[sortKey]
      }]
    };
    this.getUsersService();
  }

  getUsersService() {
    this.lux.set(state => state.createManageUserState.manageUser, { getUsersReq: this.getUsersReq });
    this.lux.set(state => state.createManageUserState.manageUser.pageIndexTracker, { currentPageIndex: this.currentPageIndex, previousPageIndex: this.previousPageIndex });
    this.createManageUserAsync.getUsers().subscribe();
  }

  goToUserAuths(userId: string, userType: string) {
    this.lux.set(state => state.createManageUserState, { userInfoToViewManageAuth: {userId, userType} });
    this.router.navigate(['/viewManageAuth']);
  }

  manageUser(user: any) {
    this.manageUserDialog = true;
    const REQUEST = {
      userIdentifiers: [user.userIdentifier],
      userStatus: user.userStatus === 'Enabled' ? 'A' : 'I'
    }
    this.createManageUserAsync.getUserDetails(REQUEST).subscribe();
  }

  updateModifiedUserDetails() {
    const modifiedValues = {};
    Object.keys(this.updateUserForm.controls).forEach(key => {
      const currentControl = this.updateUserForm.controls[key];
      if (currentControl.dirty) {
        modifiedValues[key] = currentControl.value;
      }
    });
    this.updateUserInfo(this.userDetails.userIdentifier, modifiedValues)
  }

  updateUserInfo(userIdentifier: string, request: any) {
    this.createManageUserAsync.updateUserDetails(userIdentifier, request).subscribe(() => {
      if(!this.manageUserDialog) {
        setTimeout(() => this.getUsersList({'previousPageIndex':this.previousPageIndex,'pageIndex':this.currentPageIndex,'length':this.pageSize}), 1000);
      }
    });
  }

  resetUpdateUserForm() {
    this.manageUserDialog = false;
    this.updateUserForm.reset();
  }
}
