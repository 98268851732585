<div class="confirmation-component">
  <h4>Confirmation</h4>
  <h5><strong>Thank you for registering your Producer Toolkit Client Administrator account.</strong></h5>
  <label><strong>User Name: </strong></label><span>{{userInfo && userInfo.userIdentifier}}</span>
  <p>You can now begin creating and editing Producer Toolkit accounts for others in organization.<br>
    To log in to the toolkit, go to {{link}} or click below.
    For more information about how to create and edit Producer Toolkit users--including frequently asked
    questions and a video demo--log in to the Toolkit and click the Help tab.</p>
  <h6>After creating a new account for a user:</h6>
  <ul>
    <li>Notify the user of their user name.</li>
    <li>Direct the user to {{link}}</li>
    <li><strong>If applicable, remind users that existing Producer Toolkit accounts will be deactivated in 30 days</strong></li>
  </ul>
  <a routerLink="/login">Click to login</a>
</div>
