import _Handlebars2 from '../../../../../packages/@okta/courage-dist/esm/lib/handlebars/dist/cjs/handlebars.runtime.js';
import { View, loc, internal } from '../../../../../packages/@okta/courage-dist/esm/src/CourageForSigninWidget.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/framework/Model.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/vendor/lib/backbone.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/util/jquery-wrapper.js';
import oktaUnderscore from '../../../../../packages/@okta/courage-dist/esm/src/courage/util/underscore-wrapper.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/models/Model.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/models/BaseModel.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/framework/View.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/views/Backbone.ListView.js';
import { FORMS } from '../../../ion/RemediationConstants.js';
const {
  Notification: Notification
} = internal.views.components;
const {
  Clipboard: Clipboard
} = internal.util;
var EnrollChannelPollDescriptionView = View.extend({
  template: _Handlebars2.template({
    "1": function (container, depth0, helpers, partials, data) {
      var helper,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<ol class=\"qrcode-info ov-info\"><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.qrcode.step1"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 48
          },
          "end": {
            "line": 1,
            "column": 114
          }
        }
      })) + "</li><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.qrcode.step2"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 123
          },
          "end": {
            "line": 1,
            "column": 189
          }
        }
      })) + "</li><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.qrcode.step3"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 198
          },
          "end": {
            "line": 1,
            "column": 264
          }
        }
      })) + "</li></ol><div class=\"qrcode-container\"><img class=\"qrcode\" src=" + alias3((helper = (helper = lookupProperty(helpers, "href") || (depth0 != null ? lookupProperty(depth0, "href") : depth0)) != null ? helper : alias2, typeof helper === "function" ? helper.call(alias1, {
        "name": "href",
        "hash": {},
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 328
          },
          "end": {
            "line": 1,
            "column": 336
          }
        }
      }) : helper)) + " alt=\"" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "mfa.altQrCode"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 342
          },
          "end": {
            "line": 1,
            "column": 387
          }
        }
      })) + "\"></img></div>";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<ul class=\"email-info ov-info\"><li>" + ((stack1 = (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, {
        "name": "i18n",
        "hash": {
          "arguments": "email",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.email.info"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 456
          },
          "end": {
            "line": 1,
            "column": 540
          }
        }
      })) != null ? stack1 : "") + "</li><li class=\"switch-channel-content\"></li></ul>";
    },
    "5": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<ul class=\"sms-info ov-info\"><li>" + ((stack1 = (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, {
        "name": "i18n",
        "hash": {
          "arguments": "phoneNumber",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.sms.info"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 649
          },
          "end": {
            "line": 1,
            "column": 737
          }
        }
      })) != null ? stack1 : "") + "</li><li class=\"switch-channel-content\"></li></ul>";
    },
    "7": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<div class=\"sameDevice-setup\"><p class=\"explanation\" data-se=\"subheader\">" + ((stack1 = lookupProperty(helpers, "if").call(alias1, (stack1 = depth0 != null ? lookupProperty(depth0, "deviceMap") : depth0) != null ? lookupProperty(stack1, "isDesktop") : stack1, {
        "name": "if",
        "hash": {},
        "fn": container.program(8, data, 0),
        "inverse": container.program(10, data, 0),
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 895
          },
          "end": {
            "line": 1,
            "column": 1479
          }
        }
      })) != null ? stack1 : "") + "</p><ol class=\"ov-info\">" + ((stack1 = lookupProperty(helpers, "if").call(alias1, (stack1 = depth0 != null ? lookupProperty(depth0, "deviceMap") : depth0) != null ? lookupProperty(stack1, "platformLC") : stack1, {
        "name": "if",
        "hash": {},
        "fn": container.program(12, data, 0),
        "inverse": container.noop,
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 1503
          },
          "end": {
            "line": 1,
            "column": 1740
          }
        }
      })) != null ? stack1 : "") + "<li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "$1": "<span class='semi-strong'>$1</span>",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.customUri.setup"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 1744
          },
          "end": {
            "line": 1,
            "column": 1860
          }
        }
      })) + "</li><li><a href=\"" + alias3(container.lambda((stack1 = depth0 != null ? lookupProperty(depth0, "deviceMap") : depth0) != null ? lookupProperty(stack1, "setupOVUrl") : stack1, depth0)) + "\" class=\"button button-primary setup-button\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.title"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 1947
          },
          "end": {
            "line": 1,
            "column": 2012
          }
        }
      })) + "</a></li>" + ((stack1 = lookupProperty(helpers, "if").call(alias1, depth0 != null ? lookupProperty(depth0, "showAnotherDeviceLink") : depth0, {
        "name": "if",
        "hash": {},
        "fn": container.program(14, data, 0),
        "inverse": container.noop,
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2021
          },
          "end": {
            "line": 1,
            "column": 2360
          }
        }
      })) != null ? stack1 : "") + "</ol></div>";
    },
    "8": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<div class=\"desktop-instructions ov-info\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "$1": "<span class='semi-strong'>$1</span>",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.customUri.prompt"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 964
          },
          "end": {
            "line": 1,
            "column": 1081
          }
        }
      })) + "</div><div class=\"desktop-instructions\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "$1": "<span class='semi-strong'>$1</span>",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.customUri.noPrompt"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 1121
          },
          "end": {
            "line": 1,
            "column": 1240
          }
        }
      })) + "</div><div class=\"desktop-instructions\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.customUri.makeSureHaveOV"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 1280
          },
          "end": {
            "line": 1,
            "column": 1364
          }
        }
      })) + "</div>";
    },
    "10": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return container.escapeExpression((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.customUri.makeSureHaveOVToContinue"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 1378
          },
          "end": {
            "line": 1,
            "column": 1472
          }
        }
      }));
    },
    "12": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = container.escapeExpression,
        alias2 = container.lambda,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<li><a aria-label='" + alias1((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "customUri.required.content.download.linkText"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 1550
          },
          "end": {
            "line": 1,
            "column": 1625
          }
        }
      })) + "' href=\"" + alias1(alias2((stack1 = depth0 != null ? lookupProperty(depth0, "deviceMap") : depth0) != null ? lookupProperty(stack1, "downloadHref") : stack1, depth0)) + "\" class=\"app-store-logo " + alias1(alias2((stack1 = depth0 != null ? lookupProperty(depth0, "deviceMap") : depth0) != null ? lookupProperty(stack1, "platformLC") : stack1, depth0)) + "-app-store-logo\"></a></li>";
    },
    "14": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<li>" + ((stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, (stack1 = depth0 != null ? lookupProperty(depth0, "deviceMap") : depth0) != null ? lookupProperty(stack1, "isDesktop") : stack1, {
        "name": "if",
        "hash": {},
        "fn": container.program(15, data, 0),
        "inverse": container.program(17, data, 0),
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2054
          },
          "end": {
            "line": 1,
            "column": 2348
          }
        }
      })) != null ? stack1 : "") + "</li>";
    },
    "15": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return container.escapeExpression((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, {
        "name": "i18n",
        "hash": {
          "$1": "<a class='orOnMobileLink' href='#'>$1</a>",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.customUri.orOnMobile"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2081
          },
          "end": {
            "line": 1,
            "column": 2208
          }
        }
      }));
    },
    "17": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return container.escapeExpression((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, {
        "name": "i18n",
        "hash": {
          "$1": "<a class='orOnMobileLink' href='#'>$1</a>",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.customUri.orMobile"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2216
          },
          "end": {
            "line": 1,
            "column": 2341
          }
        }
      }));
    },
    "19": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return ((stack1 = lookupProperty(helpers, "if").call(alias1, depth0 != null ? lookupProperty(depth0, "sameDevice") : depth0, {
        "name": "if",
        "hash": {},
        "fn": container.program(20, data, 0),
        "inverse": container.noop,
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2379
          },
          "end": {
            "line": 1,
            "column": 3256
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, depth0 != null ? lookupProperty(depth0, "deviceBootstrap") : depth0, {
        "name": "if",
        "hash": {},
        "fn": container.program(22, data, 0),
        "inverse": container.noop,
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3256
          },
          "end": {
            "line": 1,
            "column": 4037
          }
        }
      })) != null ? stack1 : "");
    },
    "20": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        alias4 = container.lambda,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<p class=\"explanation\" data-se=\"subheader\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.subtitle "
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2440
          },
          "end": {
            "line": 1,
            "column": 2509
          }
        }
      })) + "</p><ol class=\"sameDevice-info ov-info\"><li>" + ((stack1 = (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "arguments": "sameDevice.downloadHref",
          "bundle": "login",
          "code": "enroll.oda.without.account.step1"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2553
          },
          "end": {
            "line": 1,
            "column": 2654
          }
        }
      })) != null ? stack1 : "") + "</li><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.openOv"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2663
          },
          "end": {
            "line": 1,
            "column": 2729
          }
        }
      })) + "</li><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.signInUrl"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2738
          },
          "end": {
            "line": 1,
            "column": 2807
          }
        }
      })) + "<br><br/><span class='semi-strong no-translate'>" + alias3(alias4((stack1 = depth0 != null ? lookupProperty(depth0, "sameDevice") : depth0) != null ? lookupProperty(stack1, "orgUrl") : stack1, depth0)) + "</span><a data-clipboard-text=\"" + alias3(alias4((stack1 = depth0 != null ? lookupProperty(depth0, "sameDevice") : depth0) != null ? lookupProperty(stack1, "orgUrl") : stack1, depth0)) + "\" class=\"button link-button copy-org-clipboard-button\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "enroll.oda.org.copyLink"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 2983
          },
          "end": {
            "line": 1,
            "column": 3037
          }
        }
      })) + "</a></li><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.skipAuth.followInstruction"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3050
          },
          "end": {
            "line": 1,
            "column": 3136
          }
        }
      })) + "</li></ol><p class=\"closing\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.skipAuth.canBeClosed"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3165
          },
          "end": {
            "line": 1,
            "column": 3245
          }
        }
      })) + "</p>";
    },
    "22": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<p class=\"explanation\" data-se=\"subheader\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.skipAuth.subtitle"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3322
          },
          "end": {
            "line": 1,
            "column": 3399
          }
        }
      })) + "</p><ol class=\"deviceBootstrap-info ov-info\"><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "$1": "<span class='semi-strong'>$1</span>",
          "arguments": "enrolledDeviceName",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.skipAuth.openOv.suchAs"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3448
          },
          "end": {
            "line": 1,
            "column": 3602
          }
        }
      })) + "</li><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.skipAuth.selectAccount"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3611
          },
          "end": {
            "line": 1,
            "column": 3693
          }
        }
      })) + "</li><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "$1": "<span class='semi-strong'>$1</span>",
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.skipAuth.addAccount"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3702
          },
          "end": {
            "line": 1,
            "column": 3822
          }
        }
      })) + "</li><li>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.skipAuth.followInstruction"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3831
          },
          "end": {
            "line": 1,
            "column": 3917
          }
        }
      })) + "</li></ol><p class=\"closing\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, {
        "name": "i18n",
        "hash": {
          "bundle": "login",
          "code": "oie.enroll.okta_verify.setup.skipAuth.canBeClosed"
        },
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 3946
          },
          "end": {
            "line": 1,
            "column": 4026
          }
        }
      })) + "</p>";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return ((stack1 = lookupProperty(helpers, "if").call(alias1, depth0 != null ? lookupProperty(depth0, "href") : depth0, {
        "name": "if",
        "hash": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 408
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, depth0 != null ? lookupProperty(depth0, "email") : depth0, {
        "name": "if",
        "hash": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 408
          },
          "end": {
            "line": 1,
            "column": 597
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, depth0 != null ? lookupProperty(depth0, "phoneNumber") : depth0, {
        "name": "if",
        "hash": {},
        "fn": container.program(5, data, 0),
        "inverse": container.noop,
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 597
          },
          "end": {
            "line": 1,
            "column": 794
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, (stack1 = depth0 != null ? lookupProperty(depth0, "deviceMap") : depth0) != null ? lookupProperty(stack1, "setupOVUrl") : stack1, {
        "name": "if",
        "hash": {},
        "fn": container.program(7, data, 0),
        "inverse": container.program(19, data, 0),
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 794
          },
          "end": {
            "line": 1,
            "column": 4044
          }
        }
      })) != null ? stack1 : "");
    },
    "useData": true
  }),
  /* eslint complexity: [2, 20] */getTemplateData: function () {
    var _contextualData$qrcod;
    const contextualData = this.options.appState.get('currentAuthenticator').contextualData;
    let enrolledDeviceName = '';
    let deviceMap = {};
    let showAnotherDeviceLink = false;
    if (contextualData) {
      var _contextualData$samed, _contextualData$devic;
      if (contextualData !== null && contextualData !== void 0 && contextualData.devicebootstrap && contextualData !== null && contextualData !== void 0 && contextualData.devicebootstrap.enrolledDevices) {
        const enrolledDevices = contextualData === null || contextualData === void 0 ? void 0 : contextualData.devicebootstrap.enrolledDevices;
        enrolledDeviceName = Array.isArray(enrolledDevices) && !oktaUnderscore.isEmpty(enrolledDevices) ? enrolledDevices[0] : enrolledDevices;
      }
      if (contextualData.samedevice && (_contextualData$samed = contextualData.samedevice) !== null && _contextualData$samed !== void 0 && _contextualData$samed.setupOVUrl) {
        deviceMap = contextualData.samedevice;
      } else if (contextualData.devicebootstrap && (_contextualData$devic = contextualData.devicebootstrap) !== null && _contextualData$devic !== void 0 && _contextualData$devic.setupOVUrl) {
        deviceMap = contextualData.devicebootstrap;
      }
    }
    if (deviceMap.platform) {
      deviceMap.platformLC = deviceMap.platform.toLowerCase();
      deviceMap.isDesktop = !(deviceMap.platformLC === 'ios' || deviceMap.platformLC === 'android');
    }
    if (deviceMap.securityLevel && deviceMap.securityLevel === 'ANY') {
      showAnotherDeviceLink = true;
    }
    return {
      href: (_contextualData$qrcod = contextualData.qrcode) === null || _contextualData$qrcod === void 0 ? void 0 : _contextualData$qrcod.href,
      email: oktaUnderscore.escape(contextualData === null || contextualData === void 0 ? void 0 : contextualData.email),
      phoneNumber: oktaUnderscore.escape(contextualData === null || contextualData === void 0 ? void 0 : contextualData.phoneNumber),
      sameDevice: contextualData === null || contextualData === void 0 ? void 0 : contextualData.samedevice,
      deviceBootstrap: contextualData === null || contextualData === void 0 ? void 0 : contextualData.devicebootstrap,
      enrolledDeviceName: enrolledDeviceName,
      deviceMap: deviceMap,
      showAnotherDeviceLink: showAnotherDeviceLink
    };
  },
  postRender: function () {
    const appState = this.options && this.options.appState;
    const model = this.model;
    this.$('.orOnMobileLink').click(function () {
      var _qrChannelField$optio;
      if (!appState || !model) {
        return;
      }
      const qrChannel = 'qrcode';
      const remediations = appState.get('remediations');
      const selectEnrollmentChannelRemediation = remediations.find(remediation => {
        return remediation.name === FORMS.SELECT_ENROLLMENT_CHANNEL;
      });
      if (!selectEnrollmentChannelRemediation) {
        return;
      }
      const idField = oktaUnderscore.find(selectEnrollmentChannelRemediation.uiSchema, schema => schema.name === 'authenticator.id');
      if (!idField) {
        return;
      }
      // filter selected channel
      const qrChannelField = oktaUnderscore.find(selectEnrollmentChannelRemediation.uiSchema, schema => schema.name === 'authenticator.channel');
      if (!qrChannelField) {
        return;
      }
      qrChannelField.options = oktaUnderscore.filter(qrChannelField === null || qrChannelField === void 0 ? void 0 : qrChannelField.options, option => option.value === qrChannel);
      qrChannelField.value = ((_qrChannelField$optio = qrChannelField.options[0]) === null || _qrChannelField$optio === void 0 ? void 0 : _qrChannelField$optio.value) || qrChannel;
      qrChannelField.sublabel = null;
      model.set('authenticator.channel', qrChannelField.value);
      model.set('authenticator.id', idField.value);
      model.set('formName', selectEnrollmentChannelRemediation.name);
      appState.trigger('saveForm', model);
    });
    Clipboard.attach('.copy-org-clipboard-button').done(() => {
      let notification = new Notification({
        message: loc('enroll.oda.org.copyLink.success', 'login'),
        level: 'success'
      });
      this.el.prepend(notification.render().el);
      return false;
    });
  }
});
export { EnrollChannelPollDescriptionView as default };
