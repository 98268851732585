import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { TabsModule } from '@roosevelt/common-ui-lib/tabs';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { IconModule } from '@roosevelt/common-ui-lib/icon';
import { SelectModule } from '@roosevelt/common-ui-lib/select';
import {TableModule} from '@roosevelt/common-ui-lib/table';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import {NotificationModule} from '@roosevelt/common-ui-lib/notification';
import { CommissionStatementsModule, initialCommissionStatementsState, IPTCommissionsStatementsState } from '@roosevelt/producer-toolkit-lib';
import { CommissionStatementsComponent } from './commission-statements.component';
import { IAlertsState, initialAlertsState } from '../store/alerts/state';




@NgModule({
  declarations: [
    CommissionStatementsComponent
  ],
  imports: [
    CommonModule,
    TabsModule,
    InputModule,
    SelectModule,
    ButtonModule,
    ReactiveFormsModule,
    TableModule,
    PaginatorModule,
    IconModule,
    LoaderModule,
    NotificationModule,
    CommissionStatementsModule
  ],

})
export class PTCommissionStatementsModule {
  constructor(lux: Lux<{
    ptCommissionsStatementsState: IPTCommissionsStatementsState, ptAlertsState: IAlertsState  }>) {
    const ptCommissionsStatementsState = JSON.parse(sessionStorage.getItem('pt-commissions-statements-state') as any);
    lux.register('ptCommissionsStatementsState', ptCommissionsStatementsState ? ptCommissionsStatementsState : initialCommissionStatementsState);

    const ptAlertsState = JSON.parse(sessionStorage.getItem('ptAlertsState') as any);
    lux.register('ptAlertsState', ptAlertsState ? ptAlertsState : initialAlertsState);


    lux.allChanges().subscribe((state:any) => {
      sessionStorage.setItem('ptAlertsState', JSON.stringify(state.ptAlertsState));
      sessionStorage.setItem('pt-commissions-statements-state', JSON.stringify(state.ptCommissionsStatementsState));
    });
  }
 }
