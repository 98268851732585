<rs-tab-group class="supporting-doc-search-container" [selectedIndex]="selectedIndex">
  <rs-tab-group-title>
    <h3>User Admin</h3>
  </rs-tab-group-title>
  <rs-tab label="Create User" style="padding: 0 30px;" (selected)="changeRoute('/create')">
    @if (selectedIndex === 0) {
      <pt-create-user></pt-create-user>
    }
  </rs-tab>
  <rs-tab label="Manage User" style="padding: 0 30px;" (selected)="changeRoute('/manage')">
    @if (selectedIndex === 1) {
      <pt-manage-user></pt-manage-user>
    }
  </rs-tab>
</rs-tab-group>

