import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register/register.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CredentialsComponent } from './credentials/credentials.component';
import { IRegistrationState, registrationInitialState } from '../store/registration/state';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration.component';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { SelectModule } from '@roosevelt/common-ui-lib/select';
import { Lux } from '@roosevelt/common-ui-lib/core';

@NgModule({
  declarations: [RegisterComponent, RegistrationComponent, CredentialsComponent, ConfirmationComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    ButtonModule,
    SelectModule,
    RouterModule,
    LoaderModule
  ]
})
export class RegistrationModule {
  constructor(lux: Lux<{ registration: IRegistrationState }>) {
    const registrationState = JSON.parse(sessionStorage.getItem('registration-state') as string);
    lux.register('registration', registrationState ? registrationState : registrationInitialState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('registration-state', JSON.stringify(state.registration)));
  }
}
