export const registrationInitialState: IRegistrationState = {
  isFetching: false,
  administrators: {
    userIdentifier: '',
    password: '',
    confirmPassword: '',
    validationToken: ''
  }
};

export interface IRegistrationState {
  isFetching: boolean;
  administrators: IAdministrators;
}

export interface IAdministrators {
  userIdentifier: string;
  password: string;
  confirmPassword: string;
  validationToken: string;
}
