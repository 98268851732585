import { Component, ElementRef } from '@angular/core';
import { IChangePasswordLabelModel } from '@roosevelt/authentication-lib/lib/change-password/change-password.component';

@Component({
  selector: 'pt-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent {
  maxLength = 25;
  validationParts = {
    lowercase: new RegExp('.*[a-z].*'),
    uppercase: new RegExp('.*[A-Z].*'),
    number: new RegExp('.*\\d.*'),
    special: new RegExp('.*[!@#$%^&*()].*')
  };
  labels: IChangePasswordLabelModel = {
    title: '',
    description: '',
    changePasswordBtn: 'Change Password',
    changePasswordFailed: 'Failed to change password, please check requirements and try again.',
    changePasswordSuccess: 'Successfully updated password.',
    changePasswordFailed400: 'Failed to change password due to bad request, please check requirements and try again.',
    changePasswordFailed500: 'Failed to change password due to network error, please check requirements and try again.',
  };
  constructor(private elRef: ElementRef) {
  }
  ngOnInit(): void {
    setTimeout(() => {
      const changePasswordButton = this.elRef.nativeElement.querySelector('button');
      if (changePasswordButton) {
        changePasswordButton.click();
      }
    }, 0);
  }
}
