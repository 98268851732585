import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { getAdminPayersInfo, getPayers, getTotalUserAuths } from '../store/appGeneral/selector';
import { IPTAppGeneralState, IPTUserInfo } from '../store/appGeneral/state';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { SupportingDocumentsAsync } from '../store/documents-store/async';
import { DOCUMENT } from '@angular/common';
import { AppConfig } from '../shared/app.config';
import {
  IAssociatedSubgroupDetails, IContractResponse, IDocumentsApprovalObj, IDocumentsApprovalSearchRequest, IDocumentsApprovalSearchResponse,
  IGroupInfo, initialSupportingDocumentsState, ISupportingDocumentsState
} from '../store/documents-store/state';
import { getContractDetails, getContractDetailsErrors, getDocumentsApprovalFirstLevelDetails, getDocumentsApprovalFirstLevelErrors, getPageIndexTracker, getSupportingDocRequest } from '../store/documents-store/selector';
import dayjs from 'dayjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ILabelValue } from '@roosevelt/common-ui-lib/select';
import { IUserAuthObj } from '../store/viewManageUserAuths/state';
import { IPageIndex } from '../store/user-admin/state';
import { IPTCommissionsStatementsState } from '@roosevelt/producer-toolkit-lib';

@Component({
  selector: 'app-supporting-documents',
  templateUrl: './supporting-documents.component.html',
  styleUrls: ['./supporting-documents.component.scss']
})
export class SupportingDocumentsComponent implements OnDestroy, OnInit {

  luxId = 'PT-Supporting-Documents-Comp' + this.constructor.name;
  searchDocumentsForm: FormGroup = this.formBuilder.group({});
  payersDropDown: ILabelValue[] = [];
  tinDropDown: string[] = [];
  npnDropDown: string[] = [];
  agentsList: string[] = [];
  offset = 0;
  totalRecords = 0;
  previousPageIndex = 0;
  currentPageIndex = 0;
  pageSize = 100;
  supportingDocumentsColumns: string[] = [
    'expand', 'payer', 'agencyTin', 'agencyName',  'npn', 'agentName', 'groupId', 'groupName', 'contractId', 'contractStartDate', 'contractEndDate'
  ];
  subgroupsInfoColumns: string[] = ['subgroupId', 'subgroupName', 'effectiveDate', 'status'];
  supportingDocRequest: any;
  sortKey: string = '';
  orderOfSort: { [sortKey: string]: string } = {};
  getAdminPlanInfo$: Observable<IPTUserInfo> = this.lux.get(getAdminPayersInfo, this.luxId);
  getPayers$: Observable<any[]> = this.lux.get(getPayers, this.luxId);
  adminPayer: string = '';
  payers: string[] = [];
  documentsApprovalFirstList: IDocumentsApprovalObj[] = [];
  documentsFirstSearchErrors: string = '';
  documentsApprovalSecondList: any = {};
  documentsApprovalSecondListErrors: any = {};
  groupDetails: IGroupInfo = initialSupportingDocumentsState.associatedSubgroups.response.groupInfo;
  totalSubgroupList: IAssociatedSubgroupDetails[] = [];
  limitedSubgroupList: IAssociatedSubgroupDetails[] = [];
  totalSubgroups = 0;
  subgroupPageIndex = 0;
  subgroupPageSize = 10;
  getContractDetailsError: string = '';
  isExpanded: (index: number, rowData: any) => boolean = (_i, value) => this.expandedMap[value.agentAgencyId.toString() + value.contract.id];
  expandedMap: { [agentAgencyIdContractId: string]: boolean } = {};
  subgroupsInfoDialog = false;

  getDocumentsFirstSearch$: Observable<IDocumentsApprovalSearchResponse> = this.lux.get(getDocumentsApprovalFirstLevelDetails, this.luxId);
  getDocumentsFirstSearchErrors$: Observable<string> = this.lux.get(getDocumentsApprovalFirstLevelErrors, this.luxId);
  getContractDetails$: Observable<IContractResponse> = this.lux.get(getContractDetails, this.luxId);
  getContractDetailsErrors$: Observable<string> = this.lux.get(getContractDetailsErrors, this.luxId);
  getSupportingDocRequest$: Observable<IDocumentsApprovalSearchRequest> = this.lux.get( getSupportingDocRequest, this.luxId);
  getPageIndexTracker$: Observable<IPageIndex> = this.lux.get(getPageIndexTracker, this.luxId);
  getUserAuths$: Observable<IUserAuthObj[]> = this.lux.get(getTotalUserAuths, this.luxId);

  constructor(
    private formBuilder: FormBuilder,
    private asyncService: SupportingDocumentsAsync,
    @Inject(DOCUMENT) private docRef: Document,
    private config: AppConfig,
    private lux: Lux<{
      appGeneralState: IPTAppGeneralState,
      supportingDocumentsState: ISupportingDocumentsState,
      ptCommissionsStatementsState: IPTCommissionsStatementsState
    }>,
  ) { }

  ngOnInit(): void {
    this.getAdminPlanInfo$.subscribe(adminPlanInfo => {
      const planFromUrl = this.docRef.location.pathname.split('/')[1];
      this.adminPayer = this.config.plansAllowed[planFromUrl];
      this.getPayers$.subscribe(payersList => {
        this.payersDropDown = payersList.sort((x,y) => x.name < y.name ? 1 : -1);
       });
    });

    this.getUserAuths$.subscribe(auths => {
      const DOCUMENT_AUTHORIZED = auths.filter(auth => auth.invokingUserAuthorizations.documentsInd);
      this.tinDropDown = [...new Set(DOCUMENT_AUTHORIZED.map(auth => auth.tin).filter(auth => auth?.trim().length > 0))];
      this.npnDropDown = DOCUMENT_AUTHORIZED.map(auth => auth.npn).filter(auth => auth?.trim().length > 0);
      this.agentsList = DOCUMENT_AUTHORIZED.map(auth => auth.agentFirstName + ' ' + auth.agentLastName).filter(auth => auth !== '');
    });

    this.getDocumentsFirstSearch$.subscribe(res => {
      this.documentsApprovalFirstList = res.documentsApproval;
      this.totalRecords = res.pagination.totalRecords;
    });

    this.getPageIndexTracker$.subscribe(index => {
      this.currentPageIndex = index.currentPageIndex;
      this.previousPageIndex = index.previousPageIndex;
    });

    this.getSupportingDocRequest$.subscribe(req => {
      this.supportingDocRequest = req;
      this.pageSize = req.pagination.limit
    });

    this.getDocumentsFirstSearchErrors$.subscribe(err => this.documentsFirstSearchErrors = err);

    this.getContractDetails$.subscribe(res => {
      this.totalSubgroupList = res?.associatedSubgroups;
      this.groupDetails = res?.groupInfo;
      this.getSubgroupList({ previousPageIndex: 0, pageIndex: 0, length: 0 });
    });

    this.getContractDetailsErrors$.subscribe(err => this.getContractDetailsError = err);

    this.initializeSearchForm();
  }

  initializeSearchForm() {
    this.searchDocumentsForm = this.formBuilder.group({
      payers: [this.supportingDocRequest?.searchCriteria?.payers.length > 0 ? this.supportingDocRequest.searchCriteria?.payers : this.payersDropDown.map(p => p.value), [Validators.required]],
      agencyTIN: [this.supportingDocRequest?.searchCriteria?.agency?.tins.length > 0 ? this.supportingDocRequest.searchCriteria?.agency?.tins : this.tinDropDown],
      npns: [this.supportingDocRequest?.searchCriteria?.npns?.length > 0 ? this.supportingDocRequest?.searchCriteria?.npns : []],
      agentName: [this.supportingDocRequest?.searchCriteria?.agent?.agentNames.length > 0 ? this.supportingDocRequest?.searchCriteria?.agent?.agentNames : []],
      asOfDate: [dayjs().format('YYYY-MM-DD'),  [Validators.required]]
    });       
    if(this.tinDropDown.length === 0 && this.npnDropDown.length === 0 
          && (!this.supportingDocRequest?.searchCriteria?.agent?.agentNames || this.supportingDocRequest?.searchCriteria?.agent?.agentNames.length === 0)) {
      this.searchDocumentsForm.get('agentName')?.setValue(this.agentsList);
      this.searchDocumentsForm.updateValueAndValidity();
    } else if(this.tinDropDown.length === 0 
          && (!this.supportingDocRequest?.searchCriteria?.npns || this.supportingDocRequest?.searchCriteria?.npns?.length === 0 )) {    
      this.searchDocumentsForm.get('npns')?.setValue(this.npnDropDown);
      this.searchDocumentsForm.updateValueAndValidity();
    }

    setTimeout(() => {
      if(this.searchDocumentsForm.valid) {
        this.doSearch({'previousPageIndex':this.previousPageIndex,'pageIndex':this.currentPageIndex,'pageSize':this.pageSize});
      }
    }, 500);
  }

  doSearch(page: any) {
    if (page.pageSize) {
      this.pageSize = page.pageSize;
    }
    this.currentPageIndex = page && page.pageIndex ? Number(page.pageIndex) : 0;
    this.previousPageIndex = page && page.previousPageIndex ? Number(page.previousPageIndex) : 0;
    if (isNaN(this.currentPageIndex)) {
      return;
    }
    this.offset = this.currentPageIndex === 0 ? 0 : (this.currentPageIndex * this.pageSize);
    const FORM_VALUE = this.searchDocumentsForm.value;
    const REQUEST = {
      searchCriteria: {
        adminPayer: this.adminPayer,
        payers: FORM_VALUE.payers,
        asOfDate: dayjs(FORM_VALUE.asOfDate).format('YYYY-MM-DD'),
        documentApprovalStatus: 'APPROVED',
        ...FORM_VALUE.agencyTIN?.length > 0 && {
          agency: {
            tins: FORM_VALUE.agencyTIN
          }
        },
        ...FORM_VALUE.npns?.length > 0 && {npns: FORM_VALUE.npns},
        ...FORM_VALUE.agentName?.length > 0 && {
          agent: {
            agentNames: FORM_VALUE.agentName
          }
        }
      },
      pagination: {
        limit: this.pageSize,
        offset: this.offset
      },
      sort: {
        fieldName: 'payer',
        order: 'DESC'
      }
    }

    if((!Array.isArray(FORM_VALUE.agencyTIN) || FORM_VALUE.agencyTIN?.length === 0) &&
       (!Array.isArray(FORM_VALUE.npns) || FORM_VALUE.npns?.length === 0) &&
       (!Array.isArray(FORM_VALUE.agentName) || FORM_VALUE.agentName?.length === 0)) {
      this.lux.set(state => state.supportingDocumentsState.supportingDocuments, {errors: "Invalid Request : please provide atleast one of TIN, NPN or Agent Name"})
    } else {
      this.lux.set(state => state.ptCommissionsStatementsState.commissions.request,
        {
          underWritingPayer: REQUEST.searchCriteria.payers,
          tins: REQUEST.searchCriteria.agency?.tins,
          npns: REQUEST.searchCriteria.npns,
          agentNames: REQUEST.searchCriteria.agent?.agentNames
        }
      );
      this.updateSupportingDocRequestSession(REQUEST);
      this.getSupportingDocuments();
    }
  }

  doReset() {
    this.sortKey = "";
    this.searchDocumentsForm.reset();
    this.clearErrorsAndData();
  }

  sortResults(sortKey: string) {
    this.sortKey = sortKey;
    this.orderOfSort[sortKey] = this.orderOfSort[sortKey] ? (this.orderOfSort[sortKey] === 'ASC' ? 'DESC' : 'ASC') : 'DESC';
    const REQUEST = {
      ...this.supportingDocRequest,
      sort: {
        fieldName: this.sortKey,
        order: this.orderOfSort[sortKey]
      }
    }
    this.updateSupportingDocRequestSession(REQUEST);
    this.getSupportingDocuments()
  }

  getSupportingDocuments() {
    this.clearErrorsAndData();
    this.asyncService.getDocumentApprovalSearch(this.supportingDocRequest).subscribe();
  }

  updateSupportingDocRequestSession(request: IDocumentsApprovalSearchRequest) {
    this.lux.set(state => state.supportingDocumentsState.supportingDocuments.request, request);
    this.lux.set(state => state.supportingDocumentsState.supportingDocuments.pageIndexTracker, { currentPageIndex: this.currentPageIndex, previousPageIndex: this.previousPageIndex });
  }

  expandCollapseContract(element: IDocumentsApprovalObj) {
    this.expandedMap[element.agentAgencyId.toString() + element.contract.id] = !this.expandedMap[element.agentAgencyId.toString() + element.contract.id];
    this.documentsApprovalFirstList = [...this.documentsApprovalFirstList];
    if (this.expandedMap[element.agentAgencyId.toString() + element.contract.id]) {
      this.asyncService.getContractDocuments(element.agentAgencyId, element.contract.id.toString(), 'APPROVED').subscribe((res: any) => {
        this.documentsApprovalSecondList[element.agentAgencyId.toString() + element.contract.id] = res && res.contractDocuments ? res.contractDocuments : [];
        if (!res || !res.contractDocuments) {
          this.documentsApprovalSecondListErrors[element.agentAgencyId.toString() + element.contract.id] = typeof res === 'string' ? res : 'No results found';
        }
      });
    }
  }

  downloadContractDoc(ele: any) {
    const fileType = ele.documentName.split('.');
    this.asyncService.getDocument(ele.documentMaskId, fileType[fileType.length - 1].toUpperCase(), fileType[0]).subscribe();
  }

  getContractInfo(contractId: string, groupId: string, payer: string) {
    const CONTRACT_REQUEST = {
      contractId
    };
    const GROUP_REQUEST = {
      planAcronym: [payer],
      specifiedId: groupId
    };
    this.asyncService.getSubgroups(GROUP_REQUEST, CONTRACT_REQUEST).subscribe();
    this.subgroupsInfoDialog = true;
  }

  getSubgroupList(page: any) {
    this.totalSubgroups = this.totalSubgroupList.length;
    if (page.pageSize) { this.subgroupPageSize = page.pageSize; }
    this.subgroupPageIndex = page && page.pageIndex ? Number(page.pageIndex) : 0;
    const OFFSET = this.subgroupPageIndex === 0 ? 0 : (this.subgroupPageIndex * this.subgroupPageSize);
    const LIMIT = OFFSET + this.subgroupPageSize;
    this.limitedSubgroupList = this.totalSubgroupList.filter((s, i) => i >= OFFSET && i + 1 <= LIMIT);
  }

  clearErrorsAndData() {
    this.expandedMap = this.orderOfSort = {};
    this.lux.set(state => state.supportingDocumentsState.supportingDocuments, {errors: ''})
    this.documentsApprovalFirstList = [];
    this.documentsApprovalSecondList = {};
    this.documentsApprovalSecondListErrors = {};
  }

  ngOnDestroy(): void {

  }

}
