import { Component, OnDestroy, OnInit } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { IPTAppGeneralState, IPTUserInfo } from '../store/appGeneral/state';
import { getAdminPayersInfo, getPayers, getTotalUserAuths } from '../store/appGeneral/selector';
import { Observable} from 'rxjs';
import { ILabelValue } from '@roosevelt/common-ui-lib/select';
import { AppConfig } from '../shared/app.config';
import { IUserAuthObj } from '../store/viewManageUserAuths/state';
import { IPTCommissionsStatementsRequest, IPTCommissionsStatementsState } from '@roosevelt/producer-toolkit-lib';
import { getCommissionsRequest } from './store/selector';
import { ISupportingDocumentsState } from '../store/documents-store/state';


@Component({
  selector: 'app-commission-statements',
  templateUrl: './commission-statements.component.html',
  styleUrls: ['./commission-statements.component.scss']
})
export class CommissionStatementsComponent implements OnDestroy, OnInit {
  luxID = 'PT-Commission-Statement-Comp'+this.constructor.name;
  getAdminPlanInfo$: Observable<IPTUserInfo> = this.lux.get(getAdminPayersInfo, this.luxID);
  getPayers$: Observable<any[]> = this.lux.get(getPayers, this.luxID);
  getUserAuths$: Observable<IUserAuthObj[]> = this.lux.get(getTotalUserAuths, this.luxID);
  getCommissionsRequest$: Observable<IPTCommissionsStatementsRequest> = this.lux.get(getCommissionsRequest, this.luxID);
  payersDropDown: ILabelValue[] = [];
  tinDropDown: string[] = [];
  npnDropDown: string[] = [];
  agentsDropDowns: string[] = [];
  checkForPlan: any;

  constructor(
    private lux: Lux<{
      appGeneralState: IPTAppGeneralState,
      ptCommissionsStatementsState: IPTCommissionsStatementsState,
      supportingDocumentsState: ISupportingDocumentsState
    }>,
    private config: AppConfig,
  ){}

  ngOnDestroy(): void {
    this.lux.destroy(this.luxID);
  }

  ngOnInit(): void {
    this.checkForPlan = this.config.plansAllowed;

    this.getUserAuths$.subscribe(auths => {
      const DOCUMENT_AUTHORIZED = auths.filter(auth => auth.invokingUserAuthorizations.groupCommissionStatementsInd || auth.invokingUserAuthorizations.individualCommissionStatementsInd);
      this.tinDropDown = DOCUMENT_AUTHORIZED.map(auth => auth.tin).filter(auth => auth?.trim().length > 0);     
      this.npnDropDown = DOCUMENT_AUTHORIZED.map(auth => auth.npn).filter(auth => auth?.trim().length > 0);
      this.agentsDropDowns = DOCUMENT_AUTHORIZED.map(auth => auth.agentFirstName + ' ' + auth.agentLastName).filter(auth => auth !== '');
    });

    this.getAdminPlanInfo$.subscribe(() => {
      this.getPayers$.subscribe(payersList => this.payersDropDown = payersList.sort((x,y) => x.name < y.name ? 1 : -1));
    });

    this.getCommissionsRequest$.subscribe(commReq => {
      this.lux.set(state => state.supportingDocumentsState.supportingDocuments.request.searchCriteria,
        {
          payers: commReq.underWritingPayer,
          agency: {
            tins: commReq.tins || []
          },
          npns: commReq.npns || [],
          agent: {
            agentNames: commReq.agentNames || []
          }
        }
      );

    });
  }

}
