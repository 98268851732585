<rs-loader *ngIf="isUserCreationInProcess"></rs-loader>
<rs-dialog [(visible)]="isUserGotCreated" heading="User Created" maxWidth="50%" class="pt-create-user" (visibleChange)="createUserForm.reset();">
    <div style="padding: 20px;">
      <p>A new user has been created! The user will receive a welcome email. Please do the following:</p>
      <ul>
      <li>Add Authorizations</li>
      <li>Let the User know their Username as they will need this to set up a Password</li>
      </ul>
    </div>
    <div style="padding: 20px;justify-content: space-between;display: flex;">
        <button style="width: auto;" rs-raised-button color="primary" (click)="createUserForm.reset();isUserGotCreated = false">Create Another User</button>
    </div>
</rs-dialog>
<form [formGroup]="createUserForm" class="pt-create-user" (ngSubmit)="createUser()">
    <rs-notification type="error" [visible]="createUserErrors.length > 0" closeable (visibleChange)="resetErrors()" >
      <span>{{createUserErrors}}</span>
    </rs-notification>
    <h4>Create New User</h4>
    <div class="row">
        <div class="col-sm-4">
            <rs-input validation="eager" formControlName="userIdentifier" class="create-user-inputs" label="User Name:"></rs-input>
        </div>
    </div>
    <h4>User Profile Information</h4>
    <div class="row">
        <div class="col-sm-4">
            <rs-input validation="eager" formControlName="firstName" class="create-user-inputs" label="First Name:"></rs-input>
            <rs-input validation="eager" formControlName="lastName" class="create-user-inputs" label="Last Name:"></rs-input>
            <rs-input validation="eager" formControlName="companyName" class="create-user-inputs" label="Company Name:"></rs-input>
            <rs-input validation="eager" type="email" formControlName="emailAddress" class="create-user-inputs" label="Email Address:"></rs-input>
            <rs-input validation="eager" type="email" formControlName="confirmEmail" class="create-user-inputs" label="Confirm Email Address:"></rs-input>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4" style="justify-content: flex-end;display: flex;">
            <button rs-button type="button" (click)="createUserForm.reset(); createUserErrors = '';">Reset</button>
            <button [disabled]="!createUserForm.valid" rs-raised-button color="primary">Create User</button>
        </div>
    </div>
</form>
