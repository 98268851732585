<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h3 class="search-banner-title">Supporting Documents</h3>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <ul class="list-inline search-nav-list"></ul>
      </div>
    </div>
    <div class="row search-comp">
      <div class="card col-sm-12">
        <div class="commissions-statements-list">
          <div class=" row col-12">
            <rs-notification type="error" [visible]="documentsFirstSearchErrors.length > 0" closeable (visibleChange)="documentsFirstSearchErrors = ''">{{documentsFirstSearchErrors}}</rs-notification>
            <form *ngIf="searchDocumentsForm" [formGroup]="searchDocumentsForm" (ngSubmit)="doSearch({'previousPageIndex':0,'pageIndex':0,'length':0})">
              <div class="row">
                <div class="col-2">
                  <rs-select
                    label="Payers"
                    multiple
                    searchable
                    validation="eager"
                    [items]="payersDropDown"
                    formControlName="payers">
                  </rs-select>
                </div>
                <div class="col-2">
                  <rs-select
                    validation="eager"
                    multiple
                    searchable
                    label="Agency TIN"
                    [items]="tinDropDown"
                    formControlName="agencyTIN">
                  </rs-select>
                </div>
                <div class="col-2">
                  <rs-select
                    validation="eager"
                    multiple
                    searchable
                    label="NPN"
                    [items]="npnDropDown"
                    formControlName="npns">
                  </rs-select>
                </div>
                <div class="col-2">
                  <rs-select
                    validation="eager"
                    label="Agent Name"
                    multiple
                    searchable
                    formControlName="agentName"
                    [items]="agentsList">
                  </rs-select>
                </div>
                <div class="col-2">
                  <rs-input
                    validation="eager"
                    label="Contract As Of Date"
                    type="date"
                    formControlName="asOfDate"
                    id="asOfDate">
                  </rs-input>
                </div>
                <div class="col-2 row doc-search-btns-holder">
                  <button rs-button type="button" class="col-1" (click)="doReset()">RESET</button>
                  <button rs-raised-button class="col-1" [disabled]="!searchDocumentsForm.valid" type="submit">SEARCH</button>
                </div>
              </div>
            </form>
          </div>
          <div class="row" style="padding-top: 20px;">
            <div class="col-12">
              <table rs-table multiTemplateDataRows
                     [dataSource]="documentsApprovalFirstList"
                     style="width:100%;">
                <tr rs-header-row *rsHeaderRowDef="supportingDocumentsColumns"></tr>
                <tr rs-row *rsRowDef="let row; columns: supportingDocumentsColumns"></tr>

                <ng-container rsColumnDef="expand">
                  <th rs-header-cell *rsHeaderCellDef class="text-elip col-1" style="width: 6%;"></th>
                  <td rs-cell *rsCellDef="let element" class="text-elip col-1" style="width: 6%;">
                      <div style="display: flex;cursor: pointer;">
                          <rs-icon style="font-size: 32px" (click)="expandCollapseContract(element)">{{expandedMap[element.agentAgencyId.toString()+element.contract.id] ? 'collapse' : 'expand'}}</rs-icon>
                      </div>
                  </td>
                </ng-container>

                <ng-container rsColumnDef="payer">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('payer')" style="width: 6%;">Payer
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'payer'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element" style="width: 6%;">{{element.payer}}</td>
                </ng-container>

                <ng-container rsColumnDef="agencyTin">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('agency.tin')" style="width: 9%;">TIN
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'agency.tin'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element" style="width: 9%;">{{element.agency.tin}}</td>
                </ng-container>

                <ng-container rsColumnDef="agencyName">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('agency.name')" style="width: 10%;">Agency Name
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'agency.name'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element" style="width: 10%;">{{element.agency.name}}</td>
                </ng-container>

                <ng-container rsColumnDef="npn">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('agent.npn')" style="width: 9%;"> NPN
                  </th>
                  <td rs-cell *rsCellDef="let element" style="width: 9%;">{{element.agent.npn}}</td>
                </ng-container>

                <ng-container rsColumnDef="agentName">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('agent.name')" style="width: 10%;">Agent Name
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'agent.name'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element" style="width: 10%;">{{element.agent.name}}</td>
                </ng-container>

                <ng-container rsColumnDef="groupId">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('group.specifiedIdentifier')" style="width: 6%;">Group ID
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'group.specifiedIdentifier'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element" style="width: 6%;">{{element.group.specifiedIdentifier}}</td>
                </ng-container>

                <ng-container rsColumnDef="groupName">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('group.name')" style="width: 10%;" class="text-elip">Group Name
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'group.name'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element" [title]="element.group.name" style="width: 10%;" class="text-elip">{{element.group.name}}</td>                </ng-container>

                <ng-container rsColumnDef="contractId">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('contract.id')" style="width: 10%;">Contract ID
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'contract.id'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element"
                    style="color: #41a928; font-weight: bold; cursor: pointer; width: 10%;"
                    (click)="getContractInfo(element.contract.id, element.group.specifiedIdentifier, element.payer)">
                    {{element.contract.id}}
                  </td>
                </ng-container>

                <ng-container rsColumnDef="contractStartDate">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('contract.startDate')" style="width: 11%;">Contract Start Date
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'contract.startDate'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element" style="width: 11%;">{{element.contract.startDate | date: 'MM/dd/yyyy'}}</td>
                </ng-container>

                <ng-container rsColumnDef="contractEndDate">
                  <th rs-header-cell *rsHeaderCellDef (click)="sortResults('contract.endDate')" style="width: 11%;">Contract End Date
                    <rs-icon style="font-size: 14px" *ngIf="sortKey === 'contract.endDate'">{{orderOfSort[sortKey] && orderOfSort[sortKey] === 'ASC' ? 'up' : 'down'}}</rs-icon>
                  </th>
                  <td rs-cell *rsCellDef="let element" style="width: 11%;">{{element.contract.endDate | date: 'MM/dd/yyyy'}}</td>
                </ng-container>

                <ng-container rsColumnDef="expanded">
                  <td rs-cell *rsCellDef="let element" colspan="7" style="padding: 0;">
                    <ng-container *ngTemplateOutlet="expandedRow; context: {source: element, index: 0}"></ng-container>
                  </td>
                </ng-container>
                <tr class="rs-row" *rsNoDataRow>
                  <td colspan="12" style="text-align: center; height: 150px" class="rs-cell">
                    <p>No Supporting Documents Found</p>
                    <p>Please try again!</p>
                  </td>
                </tr>
                <tr rs-row *rsRowDef="let element; columns: ['expanded']; when: isExpanded"></tr>
              </table>
            </div>
          </div>
          <div style="padding-bottom:50px">
            <ng-container *ngIf="documentsApprovalFirstList && documentsApprovalFirstList.length> 0">
              <rs-paginator [pageIndex]="currentPageIndex" [length]="totalRecords" [pageSize]="pageSize"
                            [pageSizeOptions]="[10,100]" (page)="doSearch($event)" showFirstLastButtons>
              </rs-paginator>
            </ng-container>
          </div>
        </div>
        <ng-template #expandedRow let-source="source" let-index="index">
          <table style="width: 95%;
            table-layout: fixed;
            border: solid 1px rgb(221,222,222);
            margin: 5px 40px;
            border-radius: 5px;" rs-table  multiTemplateDataRows class="table search-table colored-table" [dataSource]="documentsApprovalSecondList[source.agentAgencyId.toString()+source.contract.id]">
            <tr rs-header-row *rsHeaderRowDef="['documentName','uploadDate', 'downloadDoc']"></tr>
            <tr rs-row *rsRowDef="let row; columns: ['documentName','uploadDate', 'downloadDoc']"></tr>
            <ng-container rsColumnDef="documentName">
              <th rs-header-cell *rsHeaderCellDef>Document Name</th>
              <td rs-cell *rsCellDef="let element"
                style="color: #41a928; width:  1%; padding-right: 10px; font-weight: bold; cursor: pointer;">
                {{element.documentName}}
              </td>
            </ng-container>
            <ng-container rsColumnDef="uploadDate">
              <th rs-header-cell *rsHeaderCellDef>Upload Date</th>
              <td rs-cell *rsCellDef="let element">{{element.uploadDate}}</td>
            </ng-container>
            <ng-container rsColumnDef="downloadDoc">
              <th rs-header-cell *rsHeaderCellDef>Download Doc</th>
              <td rs-cell *rsCellDef="let element">
                <rs-icon style="cursor: pointer;" (click)="downloadContractDoc(element)">download</rs-icon>
              </td>
            </ng-container>
            <tr class="rs-row" *rsNoDataRow>
              <td colspan="7" style="text-align: center; height: 150px" class="rs-cell">
                <rs-notification type="error" [visible]="documentsApprovalSecondListErrors[source.agentAgencyId.toString()+source.contract.id] && documentsApprovalSecondListErrors[source.agentAgencyId.toString()+source.contract.id].length > 0">
                {{documentsApprovalSecondListErrors[source.agentAgencyId.toString()+source.contract.id]}}
              </rs-notification>
              </td>
            </tr>
          </table>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<rs-dialog
  [visible]="subgroupsInfoDialog"
  (visibleChange)="subgroupsInfoDialog = false"
  heading="Subgroups Information"
  maxWidth="900px"
  maxHeight="500px">
  <div style="max-height: 400px;">
    <rs-notification type="error" [visible]="getContractDetailsError.length > 0" closeable (visibleChange)="getContractDetailsError = ''">{{getContractDetailsError}}</rs-notification>

    <div class="pt-subgroup">
      <div class="col-3 pa-key-value">
        <p>Group ID:</p>
        <b style="padding-left: 5px;"><span >{{groupDetails.specifiedId}}</span></b>
      </div>
      <div class="col-6 pa-key-value">
        <p>|  Group Name:</p>
        <b style="padding-left: 5px;"><span >{{groupDetails.name}}</span></b>
      </div>
      <div class="col-3 pa-key-value">
        <p>|  Group Status:</p>
        <b style="padding-left: 5px;">
          <rs-chip [type]="groupDetails.status.value === 'Active' ? 'success' : 'disabled' ">{{groupDetails.status.value}}</rs-chip>
        </b>
      </div>
    </div>
    <div style="max-height: 300px; overflow-y: auto;">
      <table *ngIf="limitedSubgroupList && limitedSubgroupList.length > 0" rs-table
              [dataSource]="limitedSubgroupList" style="width:100%; padding: 15px;"
              class="table search-table colored-table">
              <ng-container rsColumnDef="subgroupId">
                  <th rs-header-cell *rsHeaderCellDef>Subgroup ID</th>
                  <td rs-cell *rsCellDef="let element">{{element.specifiedId}}</td>
              </ng-container>
              <ng-container rsColumnDef="subgroupName">
                  <th rs-header-cell *rsHeaderCellDef>Subgroup Name</th>
                  <td rs-cell *rsCellDef="let element">{{element.name}}</td>
              </ng-container>
              <ng-container rsColumnDef="effectiveDate">
                <th rs-header-cell *rsHeaderCellDef>Effective Date</th>
                <td rs-cell *rsCellDef="let element">{{element.status.effectiveDate}}</td>
              </ng-container>
              <ng-container rsColumnDef="status">
                <th rs-header-cell *rsHeaderCellDef>Status</th>
                <td rs-cell *rsCellDef="let element">
                  <rs-chip [type]="element.status.value === 'Active' ? 'success' : 'disabled' ">{{element.status.value}}</rs-chip>
                </td>
              </ng-container>
              <tr rs-header-row *rsHeaderRowDef="subgroupsInfoColumns; sticky: true"></tr>
              <tr rs-row *rsRowDef="let row; columns: subgroupsInfoColumns"></tr>
      </table>
    </div>
    <ng-container *ngIf="limitedSubgroupList && limitedSubgroupList.length > 0">
      <rs-paginator [pageIndex]="subgroupPageIndex" [length]="totalSubgroups" [pageSize]="subgroupPageSize"
        [pageSizeOptions]="[10,25,100]" (page)="getSubgroupList($event)" showFirstLastButtons>
      </rs-paginator>
    </ng-container>
  </div>
  <div class="pt-dialog-footer">
    <button rs-raised-button (click)="subgroupsInfoDialog = false">CLOSE</button>
  </div>
</rs-dialog>
