import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { TabsModule } from '@roosevelt/common-ui-lib/tabs';
import { IPTProducersRequest, IPTProducersState } from '@roosevelt/producer-lib';
import { BookOfBusinessModule } from '@roosevelt/producer-toolkit-lib';
import { Observable } from 'rxjs';
import { getProducersRequest } from '../../store/producers/selector';

@Component({
  selector: 'pt-app-producer-tabs',
  templateUrl: './producer-tabs.component.html',
  styleUrl: './producer-tabs.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TabsModule,
    BookOfBusinessModule
  ]
})
export class ProducerTabsComponent implements OnInit, OnDestroy{
  luxId = 'PT-Producer-DetailsContainer-' + this.constructor.name;
  underWritingPayers: string[] = [];
  getProducersRequest$: Observable<IPTProducersRequest> = this.lux.get(getProducersRequest, this.luxId);
  constructor(    
    private lux: Lux<{  ptProducersState: IPTProducersState }>
) { }

ngOnDestroy(): void {
  this.lux.destroy(this.luxId);
}
ngOnInit(): void {
  this._loadComponent();
}

_loadComponent() {
  this.getProducersRequest$.subscribe((req: IPTProducersRequest) => {     
    this.underWritingPayers = (req.searchCriteria?.underWritingPayers) ? req.searchCriteria?.underWritingPayers : [];
  });
}

}
