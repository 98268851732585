import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CommissionStatementsComponent } from './commission-statements/commission-statements.component';
import { HelpComponent } from './help/help.component';
import { SupportingDocumentsComponent } from './supporting-documents/supporting-documents.component';
import { ProducersComponent } from './producers/producers.component';
import { UserAdminComponent } from './user-admin/user-admin.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './authentication/auth.guard';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { ProducerTabsComponent } from './producers/producer-tabs/producer-tabs.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavigateToBmtComponent } from './navigate-to-bmt/navigate-to-bmt.component';
import { RegistrationComponent } from './registration/registration.component';
import { OidcCallbackComponent } from './oidc-callback/oidc-callback.component';


const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'logout', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', pathMatch: 'full', component: LoginComponent},
  {path: 'commissions', pathMatch: 'full', component: CommissionStatementsComponent, canActivate: [AuthGuard]},
  {path: 'documents', pathMatch: 'full', component: SupportingDocumentsComponent, canActivate: [AuthGuard]},
  {path: 'producers', pathMatch: 'full', component: ProducersComponent, canActivate: [AuthGuard]},
  {path: 'producers/details', pathMatch: 'full', component: ProducerTabsComponent, canActivate: [AuthGuard]},
  {
    path: 'viewManageAuth',
    loadChildren: () => import('./view-manage-user-auths/view-manage-user-auths.module').then(m => m.ViewManageUserAuthsModule),
    pathMatch: 'full',
    canActivate: [AuthGuard]},
  {path: 'userAdmin/:id', component: UserAdminComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'profile', pathMatch: 'full', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'help', pathMatch: 'full', component: HelpComponent, canActivate: [AuthGuard]},
  {path: 'notAuthorized', pathMatch: 'full', component: NotAuthorizedComponent },
  { path: 'registration', component: RegistrationComponent },
  {path: 'bmt', pathMatch: 'full', component: NavigateToBmtComponent },
  { path: 'oidc-callback', pathMatch: 'full', component: OidcCallbackComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
