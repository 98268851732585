import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProducersSearchComponent } from "./producers-search/producers-search.component";
import { ProducerTabsComponent } from "./producer-tabs/producer-tabs.component";

const routes: Routes = [
  { path: '', redirectTo: 'search', pathMatch: 'full'},
  {
    path: 'search',
    component: ProducersSearchComponent
  },
  {
    path: 'details',
    component: ProducerTabsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProducersRoutingModule {}
