import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MagnoliaService } from '../store/magnolia/magnolia.service';

@Component({
  selector: 'producer-tl-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  helpContent: any;

  constructor(private magnoliaService: MagnoliaService,
              private sanitized: DomSanitizer) {
  }

  ngOnInit(): void {
    this.magnoliaService.getMagnoliaContent('help').subscribe((content) => {
      this.helpContent = this.sanitized.bypassSecurityTrustHtml(content);
    });
  }

}
