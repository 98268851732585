<div>
  <h4>Producer Toolkit Access</h4>
  <div class="row">
    <div class="col-12 profile-auths">
      <rs-notification type="error" [visible]="fetchingUserAuthsErrors.length > 0" closeable (visibleChange)="fetchingUserAuthsErrors = ''">{{fetchingUserAuthsErrors}}</rs-notification>

      <table  rs-table [dataSource]="authorizationRecords" style="width:100%;" class="table search-table colored-table">

        <ng-container rsColumnDef="TIN">
          <th rs-header-cell *rsHeaderCellDef class="col-1">TIN</th>
          <td rs-cell *rsCellDef="let element" class="col-1">{{element.tin}}</td>
        </ng-container>
        
        <ng-container rsColumnDef="agencyName">
          <th rs-header-cell *rsHeaderCellDef class="col-1">Agency Name</th>
          <td rs-cell *rsCellDef="let element" class="col-1">{{element.agencyName}}</td>
        </ng-container>

        <ng-container rsColumnDef="NPN">
          <th rs-header-cell *rsHeaderCellDef class="col-1">NPN</th>
          <td rs-cell *rsCellDef="let element" class="col-1">{{element.npn}}</td>
        </ng-container>
        
        <ng-container rsColumnDef="agentName">
          <th rs-header-cell *rsHeaderCellDef class="col-1">Agent Name</th>
          <td rs-cell *rsCellDef="let element" class="col-1">{{element.agentFirstName + ' '+ element.agentLastName}}</td>
        </ng-container>

        <ng-container rsColumnDef="groupStatement">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">Group Commission Statements</th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.invokingUserAuthorizations.groupCommissionStatementsInd" disabled></rs-checkbox>
          </td>
        </ng-container>

        <ng-container rsColumnDef="individualStatement">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">Individual Commission Statements</th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.invokingUserAuthorizations.individualCommissionStatementsInd" disabled></rs-checkbox>
          </td>
        </ng-container>

        <ng-container rsColumnDef="groupBusiness">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">Group Book of Business</th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.invokingUserAuthorizations.groupBookOfBusinessInd" disabled></rs-checkbox>
          </td>
        </ng-container>

        <ng-container rsColumnDef="individualBusiness">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">Individual Book of Business</th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.invokingUserAuthorizations.individualBookOfBusinessInd" disabled></rs-checkbox>
          </td>
        </ng-container>

        <ng-container rsColumnDef="documents">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">Documents</th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.invokingUserAuthorizations.documentsInd" disabled></rs-checkbox>
          </td>
        </ng-container>

        <tr rs-header-row *rsHeaderRowDef="tableHeaders"></tr>
        <tr rs-row *rsRowDef="let row; columns: tableHeaders"></tr>
        <tr class="rs-row" *rsNoDataRow>
          <td colspan="12" style="text-align: center; height: 150px" class="rs-cell">
            <p>No Records Found</p>
            <p>Please try again!</p>
          </td>
        </tr>

      </table>
    </div>
    <div style="padding:15px 0; position: relative;">
      @if (authorizationRecords.length > 0) {
        <rs-paginator [pageIndex]="pageIndex" [length]="totalAuths" [pageSize]="pageSize"
            [pageSizeOptions]="[10,100]" (page)="getLoggedInUserAuths($event)" showFirstLastButtons>
        </rs-paginator>
      }
    </div>
  </div>
</div>

