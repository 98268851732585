import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Lux } from "@roosevelt/common-ui-lib/core";
import { first, tap, mergeMap, catchError, of } from "rxjs";
import { CreateManageUserService } from "./api.service";
import { getCreateUserReq, getUserListReq } from "./selector";
import { ICreateManageUserState, initialUserDetails, initialUsersListRes } from "./state";

@Injectable({
  providedIn: "root"
})
export class CreateManageUserAsync {

  createUser() {
    return this.lux.get(getCreateUserReq).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState, { isUserGettingCreated: true, errors: '', userCreated: false})),
      mergeMap(req => this.createManageUser.createUser(req)),
      tap(response => {
        if(response.status === 201) {
          this.lux.set(state => state.createManageUserState, { isUserGettingCreated: false, errors: '', userCreated: true});
        } else {
          this.lux.set(state => state.createManageUserState, { isUserGettingCreated: false, errors: 'Internal System Error', userCreated: true});
        }
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (
          err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0
        ) {
          err.error.apiErrorList.forEach((item: any) => {
            errorMessage += item.errorMessage + '\n';
          });
        } else {
          errorMessage = err.error;
        }
        this.lux.set(state => state.createManageUserState, { isUserGettingCreated: false, errors: errorMessage, userCreated: false });
        return of(errorMessage);
      })
    );
  }

  getUsers() {
    return this.lux.get(getUserListReq).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState.manageUser, {
          isFetchingUsers: true,
          errorsFetchingUsers: '',
          usersList: initialUsersListRes
        })),
      mergeMap(req => this.createManageUser.getUsers(req)),
      tap(response => {
        this.lux.set(state => state.createManageUserState.manageUser, {
          isFetchingUsers: false,
          errorsFetchingUsers: '',
          usersList: response
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map((item: any) => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState.manageUser, {
          isFetchingUsers: false,
          errorsFetchingUsers: errorMessage,
          usersList: initialUsersListRes
        });
        return of(errorMessage);
      })
    );
  }

  getUserDetails(req: any) {
    return of(req).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState.manageUser, {
          isFetchingUserDetails: true,
          errorFetchingUserDetails: '',
          userDetails: initialUserDetails,
          isUserDetailsUpdated: false,
          errorUpdatingUserDetails: ''
        })),
      mergeMap(req => this.createManageUser.getUserDetails(req)),
      tap(response => {
        this.lux.set(state => state.createManageUserState.manageUser, {
          isFetchingUserDetails: false,
          errorFetchingUserDetails: '',
          userDetails: response[0]
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map((item: any) => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState.manageUser, {
          isFetchingUserDetails: false,
          errorFetchingUserDetails: errorMessage,
          userDetails: initialUserDetails
        });
        return of(errorMessage);
      })
    );
  }

  updateUserDetails(userIdentifier: string, req: any) {
    return of(req).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState.manageUser, {
          isUserDetailsUpdating: true,
          errorUpdatingUserDetails: '',
          isUserDetailsUpdated: false
        })),
      mergeMap(req => this.createManageUser.updateUserDetails(userIdentifier, req)),
      tap(response => {
        this.lux.set(state => state.createManageUserState.manageUser, {
          isUserDetailsUpdating: false,
          errorUpdatingUserDetails: '',
          isUserDetailsUpdated: true
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map((item: any) => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState.manageUser, {
          isUserDetailsUpdating: false,
          errorUpdatingUserDetails: errorMessage,
          isUserDetailsUpdated: false
        });
        return of(errorMessage);
      })
    );
  }

  constructor(
    private lux: Lux<{
      createManageUserState: ICreateManageUserState
    }>,
    private createManageUser: CreateManageUserService,
  ) { }
}
