import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrl: './user-admin.component.scss'
})
export class UserAdminComponent {
  selectedIndex: any;
  routeSub: Subscription | undefined;
  pageAndIndex: any = {
    'create': 0,
    'manage': 1
  }
  page: any;
  
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
  }
  ngOnInit(): void {
    this.routeSub = this.activeRoute.params.subscribe(params => {
      this.selectedIndex = this.pageAndIndex[params['id'] as any];
    });
  }

  changeRoute(route:string) {
    this.router.navigate(['/userAdmin'+route]);
  }


}
