<div class="login-component">
  <div class="row g-0">
    <div class="logo-container col-md-6 col-12">
      <a class="logo-delta-white" [class.logo-ren-white]="renPlan"><span class="sr-only">logo</span></a>
      <p>Log in to</p>
      <h1>Producer Toolkit &reg;</h1>
    </div>
    <div class="login-container col-md-6 col-12">
      <rs-loader position="absolute" *ngIf="hasAuth || !isSignInLoaded"></rs-loader>
      <rs-sign-in *ngIf="!hasAuth" (ready)="isSignInLoaded = true" [showForgotPassword]="showForgotPassword"></rs-sign-in>
      <div class='forgot-password'>
        <a class="form-content login-links last-links" (click)="firstTimeLogin()">First Time Login?</a>
      </div>
    </div>
  </div>
</div>

<rs-dialog [visible]="isFirstTimeLogin" (visibleChange)="isFirstTimeLogin = false" [baseZIndex]="99" heading="First Time Login">
  <div style="padding: 35px;">
    <h5>On the login page, please enter your username and click on next and proceed to the Forgot Password workflow.
      This will allow you to create a unique password, so that you can get started using the Producer Toolkit.</h5>
  </div>
</rs-dialog>
