import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonModule } from "@roosevelt/common-ui-lib/button";
import { InputModule } from "@roosevelt/common-ui-lib/input";
import { NotificationModule } from "@roosevelt/common-ui-lib/notification";
import { SelectModule } from "@roosevelt/common-ui-lib/select";
import { TableModule } from "@roosevelt/common-ui-lib/table";
import { ProducersSearchModule } from "@roosevelt/producer-lib";
import { ProducersSearchComponent } from "./producers-search/producers-search.component";
import { ProducersComponent } from "./producers.component";
import { ProducersRoutingModule } from "./producers.routing.module";

@NgModule({
  declarations: [
    ProducersComponent,
    ProducersSearchComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    InputModule,
    SelectModule,
    ButtonModule,
    TableModule,
    NotificationModule,
    ProducersSearchModule,
    ProducersRoutingModule
  ]
})
export class ProducersModule {
  constructor() {}
}
