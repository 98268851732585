import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../../shared/app.config";
import { Observable } from "rxjs";
import { replaceDynamicParams } from "../../utils/app.generic";

@Injectable({
  providedIn: 'root'
})
export class SupportingDocumentsService {

  constructor(private http: HttpClient, private config: AppConfig) { }

  getSupportingDocumentsSearch(searchCriteria: any): Observable<any> {
    return this.http.post<any>(this.config.urls.getSupportingDocumentsUrl, { ...searchCriteria });
  }

  getContractDocuments(agentAgencyId: string, contractId: string, status: string): Observable<any> {
    const url = replaceDynamicParams(this.config.urls.getContractDocuments, { agentAgencyId, contractId, status});
    return this.http.get<any>(url);
  }

  getSubgroups(request: any): Observable<any> {
    return this.http.post<any>(this.config.urls.getSubgroups, request);
  }

  getGroupDetails(request: any): Observable<any> {
    return this.http.post<any>(this.config.urls.getGroupDetails, request);
  }

  downloadDocuments(fileCode: string, fileType: string) {
    const url = this.config.urls.downloadDocuments + fileCode;
    return this.http.get(url, {
      observe: 'body',
      responseType: 'blob'
    });
  } 
}
