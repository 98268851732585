import { Injectable } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { catchError, first, mergeMap, of, tap } from 'rxjs';
import { initialAppGeneralState, IPTAppGeneralState } from './state';
import { AppGeneralService } from './api.service';
import { IAdminClients } from './state';
import { HttpErrorResponse } from '@angular/common/http';
import { initailUserAuthRes } from '../viewManageUserAuths/state';

@Injectable({
  providedIn: 'root',
})
export class PTAppGeneralAsync {

  getLoggedInUserContext(level: any, adminPlan: string) {
    return of('').pipe(
      first(),
      tap(() =>
        this.lux.set((state:any) => state.appGeneralState.adminPayers, {
          fetching: true,
          errors: '',
          data: {},
        })
      ),
      mergeMap((req) => this.appGeneralService.getLoggedInUserContext(level)),
      tap((adminClients: IAdminClients[]) => {
        const ADMINPLAN = [...new Set(adminClients.map((admnPlan:any) => admnPlan.ADMINPLAN))];
        this.lux.set((state:any) => state.appGeneralState.adminPayers, {
          fetching: false,
          errors: '',
          data: {
            adminplan: ADMINPLAN
          }
        });
      }),
      catchError((err) => {
        const errorMessage =
          err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0
            ? err.error.apiErrorList
                .map((item: any) => item.errorMessage)
                .join('\n')
            : err.name && err.name === 'TimeoutError'
            ? 'Timeout has occurred'
            : 'Internal server error';
        this.lux.set((state:any) => state.appGeneralState.adminPayers, {
          fetching: false,
          errors: errorMessage,
          data: {},
        });
        return of(err);
      })
    );
  }

  getAuthorizations(authMode:string, context: string) {
    return of('').pipe(
        first(),
        tap(() =>
          this.lux.set((state:any) => state.appGeneralState.auths, {
            fetching: true,
            errors: '',
            data: {},
          })
        ),
        mergeMap((req) => this.appGeneralService.getAuthorizations(authMode, context)),
        tap((auths: any) => {
          this.lux.set((state:any) => state.appGeneralState.auths, {
            fetching: false,
            errors: '',
            data: auths
          });
        }),
        catchError((err) => {
          const errorMessage =
            err &&
            err.error &&
            err.error.apiErrorList &&
            err.error.apiErrorList.length > 0
              ? err.error.apiErrorList
                  .map((item: any) => item.errorMessage)
                  .join('\n')
              : err.name && err.name === 'TimeoutError'
              ? 'Timeout has occurred'
              : 'Internal server error';
          this.lux.set((state:any) => state.appGeneralState.auths, {
            fetching: false,
            errors: errorMessage,
            data: {},
          });
          return of(err);
        })
      );
  }

  getPayers(adminPlan: string, configuredUnderWritingPayers: any[]) {
    return of('').pipe(
        first(),
        tap(() =>
          this.lux.set((state:any) => state.appGeneralState.payers, {
            fetching: true,
            errors: '',
            data: [],
          })
        ),
        mergeMap((req) => this.appGeneralService.getPayers()),
        tap((payers: any) => {
          const PLAN_ID_OF_SELECTED_ADMINPLAN = payers.find((payer: any) => payer.planAcronym === adminPlan)?.planId;
          this.lux.set((state:any) => state.appGeneralState.payers, {
            fetching: false,
            errors: '',
            data: payers
            .filter((payer: any) => configuredUnderWritingPayers.includes(payer.planAcronym) && (payer.planId === PLAN_ID_OF_SELECTED_ADMINPLAN || payer.adminPlanId === PLAN_ID_OF_SELECTED_ADMINPLAN)
            ).map((payer: any) => {
              const labelValue = {
                label: payer.name,
                value: payer.planAcronym,
                adminPlanId: payer.adminPlanId ? payer.adminPlanId : 0,
                planId: payer.planId
              }
              return labelValue;
            })
          });
        }),
        catchError((err) => {
          const errorMessage =
            err &&
            err.error &&
            err.error.apiErrorList &&
            err.error.apiErrorList.length > 0
              ? err.error.apiErrorList
                  .map((item: any) => item.errorMessage)
                  .join('\n')
              : err.name && err.name === 'TimeoutError'
              ? 'Timeout has occurred'
              : 'Internal server error';
          this.lux.set((state:any) => state.appGeneralState.payers, {
            fetching: false,
            errors: errorMessage,
            data: [],
          });
          return of(err);
        })
      );
  }

  getStates() {
    return of('').pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.appGeneralState.states, { fetching: true, data: [], errors: '' })),
      mergeMap(req => this.appGeneralService.getUSStates()),
      tap(results => {
        this.lux.set(state => state.appGeneralState.states, { fetching: false, data: this.dataToLabelValue(results), errors: '' });
      }),
      catchError((err: HttpErrorResponse) => {
        const errorMessage = this.processErrorMessage(err);
        this.lux.set(state => state.appGeneralState.states, { fetching: false, data: [], errors: errorMessage });
        return of(errorMessage);
      })
    );
  }

  getTotalUserAuths(req:any) {
    return of(req).pipe (
      first(),
      tap(() => {
        this.lux.set(state => state.appGeneralState.userAuths, { data: initailUserAuthRes, isFetchingAuths: true, errors: '' })
      }),
      mergeMap(x => this.appGeneralService.getTotalUserAuths(req)),
      tap(results => {
        this.lux.set(state => state.appGeneralState.userAuths, { data: results, isFetchingAuths: false, errors: '' })
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map((err:any) => err.errorMessage).join('\n');
        }
        this.lux.set(state => state.appGeneralState.userAuths, { data: initailUserAuthRes, isFetchingAuths: false, errors: errorMessage })
        return of(errorMessage);
      })
    );
  }
  getUserRoles() {
    const REQUEST = {
      pagination: {
          offset: 0,
          limit: 100,
          totalRecords: 0
      },
      sort: [],
      searchType: "self"
    };
    return of(REQUEST).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.appGeneralState.userRole, {
          isFetching: true,
          data: initialAppGeneralState.userRole.data,
          errors: ''
        })),
      mergeMap(req => this.appGeneralService.getUserRoles(REQUEST)),
      tap(response => {
        this.lux.set(state => state.appGeneralState.userRole, {
          isFetching: false,
          data: response,
          errors: ''
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map((item: any) => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.appGeneralState.userRole, {
          isFetching: false,
          errors: errorMessage,
          data: initialAppGeneralState.userRole.data
        });
        return of(errorMessage);
      })
    );
  }

  private dataToLabelValue(data) {
    return data.map(item => { return { label: item.value, value: item.code ? item.code : item.key } })
  }

  private processErrorMessage(err: HttpErrorResponse) {
    let errorMessage = '';
    if (
      err &&
      err.error &&
      err.error.apiErrorList &&
      err.error.apiErrorList.length > 0
    ) {
      err.error.apiErrorList.forEach((item: { errorMessage: string; }) => {
        errorMessage += item.errorMessage + '\n';
      });
    }
    return errorMessage;
  }

  constructor(
    private lux: Lux<{
        appGeneralState: IPTAppGeneralState;
    }>,
    private appGeneralService: AppGeneralService
  ) {}

}
