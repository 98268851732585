import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
import { ICreateUserReq, IUserListReq, IUserListRes } from './state';

@Injectable({
  providedIn: 'root'
})
export class CreateManageUserService {

  constructor(private http: HttpClient, private config: AppConfig) { }

  createUser(req: ICreateUserReq) {
    return this.http.post<any>(this.config.urls.createUserUrl, req, {
      observe: 'response',
    });
  }


  getUsers(req: IUserListReq) {
    return this.http.post<IUserListRes>(this.config.urls.getUsers, req, {
      observe: 'body'
    });
  }

  getUserDetails(req: {userIdentifiers: string[], userStatus: string}) {
    return this.http.post<any>(this.config.urls.getUserDetails, req, {
      observe: 'body'
    });
  }

  updateUserDetails(userIdentifier: string, req: any) {
    return this.http.patch<any>(this.config.urls.updateUserDetails + userIdentifier, req, {
      observe: 'body'
    });
  }
}
