import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {CommonUserService, IImageLink, Lux, commonUtil} from '@roosevelt/common-ui-lib/core';
import {OktaAuth} from '@okta/okta-auth-js';
import { DOCUMENT } from '@angular/common';
import { MagnoliaService } from './store/magnolia/magnolia.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import {combineLatest, debounceTime, distinctUntilChanged, filter, first, from, map, Observable, startWith, Subscription} from 'rxjs';
import { ISupportingDocumentsState } from './store/documents-store/state';
import { IPTAppGeneralState, IUiAuthorizations } from './store/appGeneral/state';
import {HeaderComponent} from '@roosevelt/common-ui-lib/header'
import { IAlertsState, IGeneralAlerts, IPTEntityAlertsRequest, IPTGeneralAlertsRequest } from './store/alerts/state';
import { alertErrors, getPTAlertsPagination, getPTAlertsRequest, getPTEntityAlerts, getPTGeneralAlerts, getPTGeneralAlertsPagination, getPTGeneralAlertsRequest } from './store/alerts/selector';
import { getUiAuthInfo, getUserRoleInfo, isFetchingAnyApi } from './store/appGeneral/selector';
import { getAuthorizedNavigationLinks } from './utils/navigation-links';
import { IViewManageUserAuthsState } from './store/viewManageUserAuths/state';
import { IPagination, IUserListObj } from './store/user-admin/state';
import { getImpersonationUser } from './login/store/selectors';
import { ILoginState } from './login/store/state';
import { AppConfig } from './shared/app.config';
import { AlertsAsync } from './store/alerts/async';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'producer-toolkit-ui-v01';
  magnoliaHeaderContent: any = undefined;
  magnoliaFooterContent: any = undefined;
  activeUrl: string ='';
  themeStyleLinks: any;
  navigationLinks:any[] = [];
  activationMap: { [p: string]: RegExp } = {
    '/producers': RegExp('/producers/.*'),   
    '/userAdmin/create': RegExp('/(userAdmin/.*|viewManageAuth)')
  }
  usersName: string = '';
  subscriptions: Subscription[] = [];
  inactivityDisplay: Date = new Date(0, 0, 0, 0, 0, 0, 0);
  countdownTotalInSeconds = 0;
  countdown = 0;
  showPTAlerts = false;
  ptEntityAlerts: any[] = [];
  ptGeneralAlerts: any[] = [];
  ptGeneralAlertsPagination: IPagination = { offset: 0, limit: 5, totalRecords: 0};
  ptAlertsPagination: IPagination = { offset: 0, limit: 5, totalRecords: 0};
  alertsPageIndex = 0;
  generalAlertsPageIndex = 0;
  alertsPageSize = 3;
  alertsPageOffset = 0;
  generalAlertsPageOffset = 0;
  ptAlertsRequest?: IPTEntityAlertsRequest;
  ptGeneralAlertsRequest?: IPTGeneralAlertsRequest;
  luxID: string = 'producer-toolkit-ui-v01'+this.constructor.name;
  ptEntityAlerts$: Observable<IGeneralAlerts[]> = this.lux.get(getPTEntityAlerts, this.luxID);
  ptGeneralAlerts$: Observable<IGeneralAlerts[]> = this.lux.get(getPTGeneralAlerts, this.luxID);
  ptAlertsPagination$: Observable<IPagination> = this.lux.get(getPTAlertsPagination, this.luxID);
  ptGeneralAlertsPagination$: Observable<IPagination> = this.lux.get(getPTGeneralAlertsPagination, this.luxID);
  ptAlertsRequest$: Observable<IPTEntityAlertsRequest> = this.lux.get(getPTAlertsRequest, this.luxID);
  ptGeneralAlertsRequest$: Observable<IPTGeneralAlertsRequest> = this.lux.get(getPTGeneralAlertsRequest, this.luxID);
  ptAlertErrors$: Observable<string> = this.lux.get(alertErrors, this.luxID);
  getUiAuthInfo$: Observable<IUiAuthorizations> = this.lux.get(getUiAuthInfo, this.luxID);
  getUserRoleInfo$: Observable<IUserListObj> = this.lux.get(getUserRoleInfo, this.luxID);
  authUiAttributes: string[] = [];
  ptAlertErrors: string = '';
  isFetchingAnyAPI$ = this.lux.get(isFetchingAnyApi, this.luxID);
  isFetching: boolean = false;
  registrationPage: boolean = false;
  userInfo: any = {};
  impersonationUser: any;
  isImpersonating: boolean = false;
  region = this.config.region;
  postLogoutRedirectUrl: string = '';

  constructor(
    @Inject(DOCUMENT) private docRef: Document,
    private oktaAuth: OktaAuth,
    private magnoliaService: MagnoliaService,
    private sanitized: DomSanitizer,
    private router: Router,
    private commonUserService: CommonUserService,
    private config: AppConfig,
    private alertsAsync: AlertsAsync,
    private lux: Lux<{
      ptAlertsState: IAlertsState,
      appGeneralState: IPTAppGeneralState,
      supportingDocumentsState: ISupportingDocumentsState,
      viewManageUserAuthsState: IViewManageUserAuthsState,
      login: ILoginState
    }>
  ) {
    if (this.docRef.location.pathname.split('/')[1].toLowerCase() === 'ren') {
      this.themeStyleLinks = this.sanitized.bypassSecurityTrustHtml(`<link rel="stylesheet" href="assets/rlha.css"><link rel='stylesheet' href='./assets/css/base.css'><link rel='stylesheet' href='./assets/css/renBase.css'>`);
    } else {
      this.themeStyleLinks = this.sanitized.bypassSecurityTrustHtml(`<link rel="stylesheet" href="assets/delta.css"><link rel='stylesheet' href='./assets/css/base.css'>`);
    }
    this.router.events.pipe(
      filter((val:any) => val instanceof NavigationEnd),
      map((x: NavigationEnd) => x.url),
      startWith(this.router.url)
    ).subscribe(url => {
      this.activeUrl = url;
      this.registrationPage = url.includes('registration');
      if (!this.impersonationUser) {
        this.lux.get(getImpersonationUser).pipe(first()).subscribe(name => this.impersonationUser = name);
        this.isImpersonating = !!sessionStorage.getItem('impersonate_user');
      }
    });
  }

  closeThisTab() {
    window.close();
  }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    (this.docRef.querySelector('#initail-loader-container') as HTMLElement).style.display = 'none';
    this.magnoliaService.getMagnoliaContent('headerFooter').subscribe((content) => {
      this.usersName = sessionStorage.getItem('userName') || '';
      if(this.usersName) {
        content = content.replace('profileName', this.commonUserService.getFullName());
      }
      this.magnoliaHeaderContent = this.sanitized.bypassSecurityTrustHtml(content.substring(
        content.lastIndexOf('<headerContent>') + 15,
        content.lastIndexOf('</headerContent>')
      ));
      this.magnoliaFooterContent = this.sanitized.bypassSecurityTrustHtml(content.substring(
        content.lastIndexOf('<footerContent>') + 15,
        content.lastIndexOf('</footerContent>')
      ));
    });
    from(commonUtil.authentication.status(this.oktaAuth)).subscribe(() => {
      this.setUpInactiveAndRefresh();
    });

    this.ptEntityAlerts$.subscribe(alerts => this.ptEntityAlerts = alerts);
    this.ptGeneralAlerts$.subscribe(alerts => this.ptGeneralAlerts = alerts);
    this.ptAlertsPagination$.subscribe(pagination => this.ptAlertsPagination = pagination);
    this.ptGeneralAlertsPagination$.subscribe(pagination => this.ptGeneralAlertsPagination = pagination);
    this.ptAlertsRequest$.subscribe(req => this.ptAlertsRequest = req);
    this.ptGeneralAlertsRequest$.subscribe(req => this.ptGeneralAlertsRequest = req);
    this.ptAlertErrors$.subscribe(err => this.ptAlertErrors = err);
    this.isFetchingAnyAPI$.pipe(debounceTime(10), distinctUntilChanged()).subscribe(x => this.isFetching = x);
    this.getUiAuthInfo$.subscribe(auth => {
      this.getUserRoleInfo$.subscribe((userInfo:any) => {
        this.userInfo = userInfo;
        const NAVLINKS = getAuthorizedNavigationLinks(auth.leftnav as string[]).filter(lnk => !!lnk);
        this.navigationLinks = userInfo && userInfo.hasBmtAccess && NAVLINKS?.length ? [{route: 'bmt', imgSrc: 'network', text: 'Go to Benefit Manager Toolkit'}, ...NAVLINKS] : NAVLINKS;
      })
    });
    this.postLogoutRedirectUrl = this.config.postLogoutRedirectEndpoint + this.docRef.location.pathname.split('/')[1] + '/';
  }

  headerClick(event: any) {
    if (event.target.classList.contains('magnolia-logout-btn')) {
      this.tryLoggingOut();
    }
  }

  @HostListener('window:storage', ['$event']) manageAuthState(event: StorageEvent): void {
    if (event.key === 'logout-catalyst' && event.newValue) {
      this.tryLoggingOut();
    }
  }

  tryLoggingOut() {
    localStorage.setItem('logout-catalyst', this.config.appName);
    localStorage.setItem('logout-catalyst', '');
    sessionStorage.clear();
    localStorage.clear();
    this.oktaAuth.options.postLogoutRedirectUri = this.postLogoutRedirectUrl;
    commonUtil.authentication.signOut(this.oktaAuth);
  }

  setUpInactiveAndRefresh() {
    const _inactivityTimeout = 30 * 1000 * 60;
    const { subscriptions, countdown$, shouldLogOut$, inactivityDisplay, countdownTotalInSeconds } = HeaderComponent.setUpTimeout(_inactivityTimeout);
    this.subscriptions = [
      ...this.subscriptions,
      ...subscriptions,
      countdown$.subscribe(x => this.countdown = x),
      shouldLogOut$.subscribe(() => this.tryLoggingOut())
    ];
    this.inactivityDisplay = inactivityDisplay;
    this.countdownTotalInSeconds = countdownTotalInSeconds;
  }

  viewAlerts() {
    this.showPTAlerts = true;
  }

  getAlerts(page: any) {
    if (page.pageSize) { this.alertsPageSize = page.pageSize; }
    this.alertsPageIndex = page && page.pageIndex ? Number(page.pageIndex) : 0;
    this.alertsPageOffset = this.alertsPageIndex === 0 ? 0 : (this.alertsPageIndex * this.alertsPageSize);
    this.alertsAsync.getPTEntityAlerts(this.ptAlertsRequest, this.alertsPageSize, this.alertsPageOffset).subscribe();
  }

  getGeneralAlerts(page: any) {
    if (page.pageSize) { this.alertsPageSize = page.pageSize; }
    this.generalAlertsPageIndex = page && page.pageIndex ? Number(page.pageIndex) : 0;
    this.generalAlertsPageOffset = this.generalAlertsPageIndex === 0 ? 0 : (this.generalAlertsPageIndex * this.alertsPageSize);
    this.alertsAsync.getPTGeneralAlerts(this.ptAlertsRequest, this.alertsPageSize, this.generalAlertsPageOffset).subscribe();
  }
}
