import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { ILoginState } from '../login/store/state';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { IPTAppGeneralState, IUiAuthorizations } from '../store/appGeneral/state';
import { getUiAuthInfo } from '../store/appGeneral/selector';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  getUiAuthInfo$: Observable<IUiAuthorizations | {}> = this.lux.get(getUiAuthInfo);
  isSessionActive: boolean = false;

  constructor(private router: Router, private lux: Lux<{ login: ILoginState, appGeneralState: IPTAppGeneralState }>) {
  }

  // Mapping routes with features
  authGuardRouteMapping: any = {
    '/commissions': 'commissions',
    '/documents': 'documents',
    '/producers': 'producers',
    '/producers/details': 'producers',
    '/userAdmin/create': 'userAdmin',
    '/userAdmin/manage': 'userAdmin',
    '/viewManageAuth': 'userAdmin',
    '/profile': 'profile',
    '/help': 'help'
  };

  canActivate(
    next: ActivatedRouteSnapshot,
    snapshot: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    return of('').pipe(
      first(),
      map(() => {
        let leftNavFeatures: string[] = [];
        const oktaSession = sessionStorage.getItem('okta-token-storage') as string;
        if (oktaSession?.indexOf('idToken') > -1 || oktaSession?.indexOf('accessToken') > -1) {
          this.isSessionActive = true;
        } else {
          this.isSessionActive = false;
        }
        // Check if the user has authorizations, if not auth guard won't allow the user to the route.
        this.getUiAuthInfo$.subscribe((uiAuth: IUiAuthorizations) => {
          if (uiAuth && uiAuth.leftnav) {
            leftNavFeatures = [...uiAuth.leftnav];
          }
        }).unsubscribe();        
        return leftNavFeatures.includes(this.authGuardRouteMapping[snapshot.url]);
      }),
      tap(allowed => {
        if(!allowed) {
          if (!this.isSessionActive) {
            this.router.navigate(['/login']);
          } else {
            this.router.navigate(['/notAuthorized']);
          }
        }
      })
    );
  }
}

