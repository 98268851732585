import { Injectable, Renderer2 } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { catchError, combineLatest, first, mergeMap, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IContractDocumentsResponse, IDocumentsApprovalSearchRequest, initialSupportingDocumentsState, ISupportingDocumentsState } from './state';
import { SupportingDocumentsService } from './api-service';
import { MIME_TYPE } from '../../utils/mime-type';

@Injectable({
  providedIn: 'root',
})
export class SupportingDocumentsAsync {
  private renderer: Renderer2 = undefined as any;
  constructor(
    private lux: Lux<{
        supportingDocumentsState: ISupportingDocumentsState;
    }>,
    private documentsService: SupportingDocumentsService
  ) {}

  getDocumentApprovalSearch(request: IDocumentsApprovalSearchRequest) {
    return of('').pipe(
      first(),
      tap(() => this.lux.set(state => state.supportingDocumentsState.supportingDocuments, {isFetching: true, response: {
        pagination: {
          totalRecords: 0,
          limit: 0,
          offset: 0
        },
        documentsApproval: []
      }, errors: ''})),
      mergeMap(req => this.documentsService.getSupportingDocumentsSearch(request)),
      tap(res => {
        this.lux.set(state => state.supportingDocumentsState.supportingDocuments, {
          isFetching: false,
          response: res && res.documentsApproval.length ? res : initialSupportingDocumentsState.supportingDocuments.response,
          errors: ''
        });
      }),
      catchError((err: HttpErrorResponse) => {
        const errorMessage = this.processErrorMessage(err);
        this.lux.set(state => state.supportingDocumentsState.supportingDocuments, {isFetching: false, response: {
          pagination: {
            totalRecords: 0,
            limit: 0,
            offset: 0
          },
          documentsApproval: []
        }, errors: errorMessage});
        return of(errorMessage);
      })
    );
  }

  getContractDocuments(agentAgencyId: string, contractId: string, status: string) {
    return of('').pipe(
      first(),
      tap(() => this.lux.set(state => state.supportingDocumentsState.contractDocumentsState, {isFetching: true, response: {
        totalRecords: 0,
        contractDocuments: []
      }, errors: ''})),
      mergeMap(req => this.documentsService.getContractDocuments(agentAgencyId, contractId, status)),
      tap((res:IContractDocumentsResponse) => {
        this.lux.set(state => state.supportingDocumentsState.contractDocumentsState, {
          isFetching: false,
          response: res,
          errors: res && res.contractDocuments.length ? '' : 'No results found.'
        });
        return of(res);
      }),
      catchError((err: HttpErrorResponse) => {
        const errorMessage = this.processErrorMessage(err);
        this.lux.set(state => state.supportingDocumentsState.contractDocumentsState, {isFetching: false, response: {
          totalRecords: 0,
          contractDocuments: []
        }, errors: errorMessage});
        return of(errorMessage);
      })
    );
  }

  getSubgroups(groupRequest: any, contractRequest: any) {
    return of('').pipe(
      first(),
      tap(() => this.lux.set(state => state.supportingDocumentsState.associatedSubgroups, {isFetching: true, response: initialSupportingDocumentsState.associatedSubgroups.response, errors: ''})),
      mergeMap(req => combineLatest([this.documentsService.getGroupDetails(groupRequest), this.documentsService.getSubgroups(contractRequest)])),
      tap((res: [groupInfo: any, subgroupsInfo: any]) => {
        const [groupRes, subgroupRes] = res;
        this.lux.set(state => state.supportingDocumentsState.associatedSubgroups, {
          isFetching: false,
          response: {groupInfo: groupRes.clients[0], associatedSubgroups: subgroupRes.subClients},
          errors: subgroupRes && subgroupRes.subClients.length ? '' : 'No results found.'
        });
        return of(res);
      }),
      catchError((err: HttpErrorResponse) => {
        const errorMessage = this.processErrorMessage(err);
        this.lux.set(state => state.supportingDocumentsState.associatedSubgroups, {
          isFetching: false,
          response: initialSupportingDocumentsState.associatedSubgroups.response,
          errors: errorMessage
        });
        return of(errorMessage);
      })
    );
  }

  getDocument(req: string, fileType: string, docName: string) {
    return of(req).pipe(
      first(),
      tap(() => this.lux.set(x => x.supportingDocumentsState, { isFetchingDocument: true, errorFetchingDocument: '' })),
      mergeMap(x => this.documentsService.downloadDocuments(req, fileType)),
      tap(res => {
        this.lux.set(x => x.supportingDocumentsState, { isFetchingDocument: false, errorFetchingDocument: '' });

        let blob: any;
        if (Object.keys(MIME_TYPE).includes(fileType)) {
          blob = new Blob([res], { type: MIME_TYPE[fileType] })
        } else {
          blob = new Blob([res], { type: 'application/octet-stream' })
        }
        const url= window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      }),
      catchError((e: HttpErrorResponse) => {
        let err = bufferToString(e.error);
        const errorMessage = this.processErrorMessage(err);
        this.lux.set(x => x.supportingDocumentsState, { errorFetchingDocument: errorMessage, isFetchingDocument: false });
        return of(err);
      })
    );
  }

  private processErrorMessage(err: HttpErrorResponse) {
    let errorMessage = '';
    if (
      err &&
      err.error &&
      err.error.apiErrorList &&
      err.error.apiErrorList.length > 0
    ) {
      err.error.apiErrorList.forEach((item: any) => {
        errorMessage += (item.rejectedFieldName ? item.rejectedFieldName : '') + ' ' + item.errorMessage + '\n';
      });
    } else {
      errorMessage = err.message;
    }
    return errorMessage;
  }
}

export function bufferToString(buf: any) {
  return JSON.parse(String.fromCharCode.apply(null, [...new Uint8Array(buf)]));
}
