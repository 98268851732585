import {Inject, NgModule} from '@angular/core';
import {CommonModule, DOCUMENT} from '@angular/common';
import { Lux} from '@roosevelt/common-ui-lib/core';
import {LoginComponent} from './login.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { IPTAppGeneralState, initialAppGeneralState } from '../store/appGeneral/state';
import {SignInModule} from '@roosevelt/common-ui-lib/sign-in';
import { BannerModule} from '@roosevelt/common-ui-lib/banner';
import {LoaderModule} from '@roosevelt/common-ui-lib/loader';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';
import { ILoginState, initialSolutionState, ISolutionsState, loginInitialState } from './store/state';
@NgModule({ declarations: [LoginComponent],
    exports: [
      LoginComponent
    ],
    imports: [
      CommonModule,
      LoaderModule,
      SignInModule,
      BannerModule,
      DialogModule
      ],
    providers: [provideHttpClient(withInterceptorsFromDi())] })
export class LoginModule {
  constructor(@Inject(DOCUMENT) private document: Document, lux: Lux<{
    appGeneralState: IPTAppGeneralState,login: ILoginState, solutionsLogin: ISolutionsState }>) {
    const adminPlan = this.document.location.pathname.split('/')[1];
    const appGeneralState = JSON.parse(sessionStorage.getItem('app-general-state') as any);
    const loginState = JSON.parse(sessionStorage.getItem(adminPlan + '-login-state') as any);

    lux.register('login', loginState ? loginState : loginInitialState);
    lux.register('solutionsLogin', initialSolutionState);
    lux.register('appGeneralState', appGeneralState ? appGeneralState : initialAppGeneralState);
    lux.allChanges().subscribe((state:any) => {
      sessionStorage.setItem(adminPlan + '-login-state', JSON.stringify(state.login));
      sessionStorage.setItem('app-general-state', JSON.stringify(state.appGeneralState));
    });
  }
}
