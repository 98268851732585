import { IKeyValue } from "../store/documents-store/state";

export const MIME_TYPE: IKeyValue = {
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPSX: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  CSV: 'text/csv',
  PNG: 'image/png',
  JPG: 'image/jpeg',
  JPEG: 'image/jpeg',
  TXT: 'text/plain',
  DOCM: 'application/vnd.ms-word.document.macroEnabled.12',
  DOT: 'application/msword',
  DOTX: 'application/msword'
}
