import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';

@Injectable({
  providedIn: 'root'
})
export class AlertsApiService {

  constructor(private http: HttpClient, private config: AppConfig) { }

  getPTEntityAlerts(req: any, limit: number, offset: number) {
    return this.http.post<any>(this.config.urls.ptAlertsUrl+`?limit=${limit}&offset=${offset}&sort=updatedOn,priority`, req);
  }

  getPTGeneralAlerts(req: any, limit: number, offset: number) {
    return this.http.post<any>(this.config.urls.ptGeneralAlertsUrl+`?limit=${limit}&offset=${offset}&sort=updatedOn,priority`, req);
  }
}
