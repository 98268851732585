import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { TabsModule } from '@roosevelt/common-ui-lib/tabs';
import { UserAdminComponent } from './user-admin.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { CreateUserComponent } from './create-user/create-user.component';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { ICreateManageUserState, initialCreateManageUserState } from '../store/user-admin/state';
import { NotificationModule } from '@roosevelt/common-ui-lib/notification';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { TableModule } from '@roosevelt/common-ui-lib/table';
import { IconModule } from '@roosevelt/common-ui-lib/icon';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';
import { ChipModule } from '@roosevelt/common-ui-lib/chip';



@NgModule({
  declarations: [
    UserAdminComponent,
    CreateUserComponent,
    ManageUserComponent
  ],
  imports: [
    CommonModule,
    TabsModule,
    ButtonModule,
    InputModule,
    ReactiveFormsModule,
    LoaderModule,
    FormsModule,
    DialogModule,
    NotificationModule,
    TableModule,
    IconModule,
    PaginatorModule,
    ButtonModule,
    ChipModule
  ]
})
export class UserAdminModule {
  constructor(lux: Lux<{ createManageUserState: ICreateManageUserState }>) {
    const createManageUserState = JSON.parse(sessionStorage.getItem('createManageUserState') as any);
    lux.register('createManageUserState', createManageUserState ? createManageUserState : initialCreateManageUserState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('createManageUserState', JSON.stringify(state.createManageUserState)));
  }
 }
