import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";
import {AppModule} from "./app/app.module";
import {commonUtil} from "@roosevelt/common-ui-lib/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

const loginStatus = sessionStorage.getItem('loginStatus');

if (!loginStatus) { sessionStorage.setItem('loginStatus', 'TRYING_EXISTING'); }

if (environment.production) {
  enableProdMode();
}

commonUtil.bootstrap(
  fetch(environment.configFile),
  platformBrowserDynamic,
  AppModule
).catch(err => console.error(err));

