import { AbstractControl } from '@angular/forms';

export const matchFields = (cntrl1: any, cntrl2: any, fieldCheck: string) => {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const PRIMARY_CONTROL = control.root.get(cntrl1);
      const CONFORMATION_CONTROL = control.root.get(cntrl2);
      if (CONFORMATION_CONTROL?.value !== '' && CONFORMATION_CONTROL?.value !== PRIMARY_CONTROL?.value) {
        return { [fieldCheck]: true };
      } else if (CONFORMATION_CONTROL?.value === PRIMARY_CONTROL?.value) {
        PRIMARY_CONTROL?.setErrors(null);
        CONFORMATION_CONTROL?.setErrors(null);
      }
    }
    return {};
  };
};

const userName = (value) => /^((?=[a-zA-Z]{2,})(?=[0-9]*)(?=[.@_-]*))[A-Za-z0-9.@_-]{5,25}$/.test(value);
export const isUserName = (control: AbstractControl): { [key: string]: any } => {
  const fieldValue = control.value;
  if (fieldValue && userName(fieldValue) === false) {
    return { userName: true };
  }
  return {};
};

const isPassword = (value) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()?.,])[A-Za-z\d!@#$%^&*()?.,]{12,25}$/.test(value);
export const isValidPassword = (control: AbstractControl): { [key: string]: any } => {
  const fieldValue = control.value;
  if (fieldValue && isPassword(fieldValue) === false) {
    return { password: true };
  }
  return {};
};
