<div class="credentials-component">
  <form [formGroup]="credentialsForm" #ngCredentialsForm>
    <div class="strong">Register Account <span>(Step 2 of 2)</span></div>
    <rs-input class="form-content" formControlName='userIdentifier' validation="eager" label="Username" type="text"></rs-input>
    <rs-input class="form-content" formControlName='password' label="Password" validation="eager" type="password"></rs-input>
    <rs-input class="form-content" formControlName='confirmPassword' label="Confirm Password" validation="eager" type="password"></rs-input>
    <div class="credentials-button">
      <button style="float: right" rs-raised-button color="primary" type="submit" (click)="credentialsSubmit.emit(credentialsForm.value)" [disabled]="credentialsForm.invalid">Continue</button>
    </div>
  </form>
</div>
