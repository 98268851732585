<rs-banner></rs-banner>
<div [innerHTML]="themeStyleLinks"></div>
<div *ngIf="isImpersonating" class="impersonation"
  [class.cursor]="(region === 'tst' || region === 'local')">
  Impersonating
  <span style="color: red;">&nbsp;{{impersonationUser}}</span>
  <span style="flex: 1;"></span>
  click <a (click)="closeThisTab()">&nbsp;here&nbsp;</a> to stop impersonation and close window
</div>
<!-- Magnolia Header content -->
<ng-container *ngIf="magnoliaHeaderContent">
    <div class="noprint" (click)="headerClick($event)" [innerHTML]="magnoliaHeaderContent"
    [ngStyle]="activeUrl !== '/login' && activeUrl !== '/' && activeUrl !== '/404'  && !registrationPage? {'visibility': ''} : {'visibility': 'hidden', 'height.px' : '0'}"
        >
    </div>
</ng-container>
<div style="background-color: white; margin: 0; height: calc(100vh - 192px);">
    <div style="width: 90px;padding: 0;float: left;" *ngIf="activeUrl !== '/login' && activeUrl !== '/' && activeUrl !== '/404' && !registrationPage">
        <rs-nav-stack color="secondary"
        [links]="navigationLinks" [activationMap]="activationMap"></rs-nav-stack>
    </div>
    <div [class]="activeUrl && activeUrl !== '/' && activeUrl !== '/login' &&  !registrationPage ? 'main-container' : 'login-screen'">
        <div [class]="activeUrl && activeUrl !== '/' && activeUrl !== '/login' && !registrationPage ? 'route-container' : 'login-screen-container'">
            <router-outlet></router-outlet>
        </div>

        <!-- Alerts container -->
        <div class="pt-alerts noprint" (click)="viewAlerts()" *ngIf="activeUrl !== '/login' && activeUrl !== '/' && !registrationPage">
            <span class="alert-count">{{this.ptAlertsPagination.totalRecords + this.ptGeneralAlertsPagination.totalRecords}}</span>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 456.727 456.727" style="enable-background:new 0 0 456.727 456.727;" xml:space="preserve">
                <g>
                <path d="M415.863,315.864l-30-30v-80.366c0-76.725-54.865-140.625-127.5-154.634v-20c0-16.542-13.458-30-30-30s-30,13.458-30,30v20
                c-72.635,14.01-127.5,77.909-127.5,154.634l0,80.366l-30,30v40h-30v30h30h375h30v-30h-30V315.864z" />
                <path
                    d="M228.363,455.863c27.922,0,52.019-16.353,63.255-40H165.109C176.344,439.51,200.441,455.863,228.363,455.863z" />
                <path d="M427.262,167.464l29.465-5.638c-6.407-33.488-20.473-65.803-40.674-93.451c-20.075-27.474-46.39-50.603-76.099-66.884
                L325.536,27.8C378.784,56.981,415.861,107.887,427.262,167.464z" />
                <path d="M131.19,27.8L116.772,1.491c-29.709,16.282-56.023,39.41-76.099,66.884C20.473,96.024,6.407,128.338,0,161.827
                l29.465,5.638C40.865,107.887,77.942,56.981,131.19,27.8z" />
                </g>
            </svg>
        </div>
    </div>
</div>


<!-- Magnolia Footer content -->
<ng-container *ngIf="magnoliaFooterContent">
  <div class="footer-content noprint" [innerHTML]="magnoliaFooterContent" [ngStyle]="activeUrl !== '/404' ? {'visibility': '', 'height.px' : '192'} : {'visibility': 'hidden', 'height.px' : '0'}"></div>
</ng-container>

<rs-dialog heading="User Inactivity" [visible]="!!countdown" (visibleChange)="countdown = 0">
  <p class="message">You have been inactive for {{inactivityDisplay | date:'mm \'minute(s)\' ss\'second(s)\''}}. We will auto log you out in {{countdownTotalInSeconds - countdown}} seconds.<br><br>To extend your
    session click anywhere.</p>
</rs-dialog>

<!-- Alerts Pop up -->
<ng-container *ngIf="showPTAlerts">
    <rs-dialog visible="true" (visibleChange)="showPTAlerts = false" heading="Producer Toolkit Alerts"
      maxWidth="80%">

      <div class="pt-alerts-cont" >
        <h3>General Alerts</h3>
        <div *ngIf="ptGeneralAlerts.length; else noAlertsOrErrors;">
          <div class="alert-box" *ngFor="let alert of ptGeneralAlerts;let index=index;">
            <div class="marker-icon" [ngClass]="alert?.statusType?.code!='ACTIVE'?'read':''"></div>
            <div class="row">
              <div class="col-xs-1" [ngSwitch]="alert?.priorityType?.code">
                <ng-container *ngSwitchCase="'HIGH'">
                  <p class="priority high-priority">
                    {{alert?.priorityType?.code}}
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'MEDIUM'">
                  <p class="priority medium-priority">
                    {{alert?.priorityType?.code}}
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'LOW'">
                  <p class="priority low-priority">
                    {{alert?.priorityType?.code}}
                  </p>
                </ng-container>

                <!-- <div class="dismiss-btn">
                    DISMISS
                  </div> -->
              </div>
              <div class="col-xs-10" style="padding: 0px 0px 0px 40px;">
                <div class="row">
                  <div class="col-xs-6">
                    Subject:
                    <span class="bold">{{alert?.subject}}</span>
                  </div>
                  <div class="col-xs-6">
                    <div class="row">
                      <div class="col-xs-6">
                        Send By:
                        <span class="bold">{{alert?.createdBy}}</span>
                      </div>
                      <div class="col-xs-6">
                        Administrator:
                        <span class="bold">{{alert?.adminPayerAcronym}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xs-6">
                        Created on:
                        <span class="bold">{{alert?.createdOn | date:'MM/dd/yyyy'}}</span>
                      </div>
                      <div class="col-xs-6">
                        Last Updated:
                        <span class="bold">{{alert?.updatedOn | date:'MM/dd/yyyy'}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 bold">
                    <div class="horizontal-ruler"></div>
                    <div class="message-section">
                      {{alert?.message}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <rs-paginator [pageIndex]="generalAlertsPageIndex" [length]="ptGeneralAlertsPagination.totalRecords" [pageSize]="alertsPageSize"
              (page)="getGeneralAlerts($event)" showFirstLastButtons>
          </rs-paginator>

        </div>
      </div>
      <!-- <bmt-alerts [bmtAlerts]="ptAlerts" [bmtAlertErrors]="ptAlertErrors"></bmt-alerts> -->
      <div class="pt-alerts-cont" >
        <h3>Producer Alerts</h3>
        <div *ngIf="ptEntityAlerts.length; else noAlertsOrErrors;">
          <div class="alert-box" *ngFor="let alert of ptEntityAlerts;let index=index;">
            <div class="marker-icon" [ngClass]="alert?.statusType?.code!='ACTIVE'?'read':''"></div>
            <div class="row">
              <div class="col-xs-1" [ngSwitch]="alert?.priorityType?.code">
                <ng-container *ngSwitchCase="'HIGH'">
                  <p class="priority high-priority">
                    {{alert?.priorityType?.code}}
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'MEDIUM'">
                  <p class="priority medium-priority">
                    {{alert?.priorityType?.code}}
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'LOW'">
                  <p class="priority low-priority">
                    {{alert?.priorityType?.code}}
                  </p>
                </ng-container>

                <!-- <div class="dismiss-btn">
                    DISMISS
                  </div> -->
              </div>
              <div class="col-xs-10" style="padding: 0px 0px 0px 40px;">
                <div class="row">
                  <div class="col-xs-6">
                    Subject:
                    <span class="bold">{{alert?.subject}}</span>
                  </div>
                  <div class="col-xs-6">
                    <div class="row">
                      <div class="col-xs-6">
                        Send By:
                        <span class="bold">{{alert?.createdBy}}</span>
                      </div>
                      <div class="col-xs-6">
                        Administrator:
                        <span class="bold">{{alert?.adminPayerAcronym}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xs-6">
                        Created on:
                        <span class="bold">{{alert?.createdOn | date:'MM/dd/yyyy'}}</span>
                      </div>
                      <div class="col-xs-6">
                        Last Updated:
                        <span class="bold">{{alert?.updatedOn | date:'MM/dd/yyyy'}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 bold">
                    <div class="horizontal-ruler"></div>
                    <div class="message-section">
                      {{alert?.message}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <rs-paginator [pageIndex]="alertsPageIndex" [length]="ptAlertsPagination.totalRecords" [pageSize]="alertsPageSize"
              (page)="getAlerts($event)" showFirstLastButtons>
          </rs-paginator>
        </div>
      </div>
      <ng-template #noAlertsOrErrors>
          <p [class.error]="ptAlertErrors" class="noAlerts">{{ptAlertErrors || 'No alerts'}}</p>
      </ng-template>
    </rs-dialog>
</ng-container>

<rs-loader imageSrc="default" position="absolute" *ngIf="isFetching">Loading...</rs-loader>
