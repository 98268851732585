import { Component, Inject } from '@angular/core';
import { AppConfig } from '../shared/app.config';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'pt-app-navigate-to-bmt',
  templateUrl: './navigate-to-bmt.component.html',
  styleUrl: './navigate-to-bmt.component.scss'
})
export class NavigateToBmtComponent {
  bmtLink = '';
  constructor(
    private config: AppConfig,
    @Inject(DOCUMENT) private docRef: Document
  ) {
    const pathSegment = this.docRef.location.pathname.split('/')[1].toLowerCase();
    this.bmtLink = "https://"+((this.config.region !== 'local' && this.config.region !== 'prd') ? (this.config.region+'.') : '') +this.config.urls.bmtRedirectURL+pathSegment;
    window.open(this.bmtLink, "_blank");
  }

}
