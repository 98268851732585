import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../shared/app.config';
import { ILabelValue } from '@roosevelt/common-ui-lib/select';
import { IUserListReq, IUserListRes } from '../user-admin/state';

@Injectable({
  providedIn: 'root'
})
export class AppGeneralService {

  constructor(private http: HttpClient, private config: AppConfig) { }


  getLoggedInUserContext(contextLevel: any) {
    return this.http.post<any>(this.config.urls.userContextUrl, {level:contextLevel}, {
      observe: 'body'
    });
  }

  getPayers() {
    return this.http.get<any>(this.config.urls.getPayersUrl, {
      observe: 'body'
    });
  }

  getAuthorizations(authMode:string, context: string): any {
    const url = this.config.urls.ptUIAuthorizationsUrl + '?type=' + (authMode === 'relaxed' ? 'R' : 'S') + '&' + 'context=' + context;
    return this.http.get(url);
  }

  getUSStates(): any {
    let url = this.config.urls.statesUrl;
    url = url.replace(':countryCode', 'US');
    return this.http.get<ILabelValue[]>(url);
  }

  getTotalUserAuths(req:any) {
    return this.http.post<any>(this.config.urls.getUserAuths, req);
  }

  getUserRoles(req: IUserListReq) {
    return this.http.post<IUserListRes>(this.config.urls.getUsers, req, {
      observe: 'body'
    });
  }
}
