import { ILabelValue } from "@roosevelt/common-ui-lib/select";
import { initailUserAuthRes, IViewUserAuths } from "../viewManageUserAuths/state";
import { IUserListRes } from "../user-admin/state";

export const initialAppGeneralState: IPTAppGeneralState = {
    adminPayers: {
        fetching: false,
        errors: "",
        data: {
            tins: [],
            adminplan: []
        }
    },
    payers: {
        fetching: false,
        errors: "",
        data: []
    },
    auths: {
        fetching: false,
        errors: "",
        data: {}
    },
    states: {
        fetching: false,
        errors: "",
        data: []
    },
    userAuths: {
      data: initailUserAuthRes,
      isFetchingAuths: false,
      errors: ''
    },
    userRole: {
        isFetching: false,
        data: {
            pagination: {
                limit: 100,
                offset: 0,
                totalRecords: 0
            },
            users: []
        },
        errors: ''
    }
}

export interface IPTAppGeneralState {
    adminPayers: {
        fetching: boolean;
        errors: string;
        data: IPTUserInfo;
    };
    payers: {
        fetching: boolean;
        errors: string;
        data: [];
    };
    auths: {
        fetching: boolean;
        errors: string;
        data: IUiAuthorizations;
    };
    states: {
        fetching: boolean;
        errors: string;
        data: ILabelValue[];
    };
    userAuths: IViewUserAuths;
    userRole: {
        isFetching: boolean;
        data: IUserListRes;
        errors: string;
    }
}

export interface IPTUserInfo {
    tins: string[];
    adminplan: string[];
}

export interface IAdminClients {
    ADMINPLAN: string;
    PLAN: string;
}

export interface IUiAuthorizations {
  config?: any;
  documents?: string[];
  leftnav?: string[]
}

export interface IKeyNavValue {
  [key: string]: INavigation;
}

export interface INavigation {
  route: string;
  imgSrc: string;
  text: string;
  position: number;
}
