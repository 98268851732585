import { Component, OnDestroy, OnInit } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { ILabelValue } from '@roosevelt/common-ui-lib/select';
import { Observable } from 'rxjs';
import { getPayers, getStates, getTotalUserAuths } from '../../store/appGeneral/selector';
import { IPTAppGeneralState } from '../../store/appGeneral/state';
import { PTAppGeneralAsync } from '../../store/appGeneral/async';
import { Router } from '@angular/router';
import { IUserAuthObj } from '../../store/viewManageUserAuths/state';

@Component({
  selector: 'app-producers-search',
  templateUrl: './producers-search.component.html',
  styleUrl: './producers-search.component.scss'
})
export class ProducersSearchComponent implements OnInit, OnDestroy {
  luxID = 'PT-ProducersSearch-Comp'+this.constructor.name;
  payersDropDown: ILabelValue[] = [];
  statesDropDown: ILabelValue[] = [];
  tinsDropDown: string[] = [];
  npnsDropDown: string[] = [];
  agentsDropDowns: string[] = [];
  getPayers$: Observable<any[]> = this.lux.get(getPayers, this.luxID);
  getStates$: Observable<any[]> = this.lux.get(getStates, this.luxID);
  getUserAuths$: Observable<IUserAuthObj[]> = this.lux.get(getTotalUserAuths, this.luxID);

    constructor(
    private lux: Lux<{ appGeneralState: IPTAppGeneralState }>,
    private generalAsync: PTAppGeneralAsync,
    private router: Router  ) {}

  ngOnInit(): void {
    this.getPayers$.subscribe(payersList => {
      this.payersDropDown = payersList.sort((x,y) => x.name < y.name ? 1 : -1);
    });

    this.getStates$.subscribe(statesList => {
      if (!statesList.length) {
        this.generalAsync.getStates().subscribe();
      }
      this.statesDropDown = statesList;
    });

    this.getUserAuths$.subscribe(auths => {
      const DOCUMENT_AUTHORIZED = auths.filter(auth => auth.invokingUserAuthorizations.groupBookOfBusinessInd || auth.invokingUserAuthorizations.individualBookOfBusinessInd);
      this.tinsDropDown = DOCUMENT_AUTHORIZED.map(auth => auth.tin).filter(auth => auth?.trim().length > 0);
      this.npnsDropDown = DOCUMENT_AUTHORIZED.map(auth => auth.npn).filter(auth => auth?.trim().length > 0);
      this.agentsDropDowns = DOCUMENT_AUTHORIZED.map(auth => auth.agentFirstName + ' ' + auth.agentLastName).filter(auth => auth !== '');
    });
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxID);
  }

  onGoToDetails() {
    this.router.navigateByUrl('/producers/details');
  }
}
