import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AppConfig} from '../shared/app.config';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CommonUserService, commonUtil, Lux } from '@roosevelt/common-ui-lib/core';
import { from, Observable } from 'rxjs';
import { PTAppGeneralAsync } from '../store/appGeneral/async';
import {OktaAuth} from '@okta/okta-auth-js';
import { getAuthorizedNavigationLinks } from '../utils/navigation-links';
import { AlertsAsync } from '../store/alerts/async';
import { IUserAuthObj } from '../store/viewManageUserAuths/state';
import { IPTAppGeneralState } from '../store/appGeneral/state';
import { getTotalUserAuths } from '../store/appGeneral/selector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{
  luxID = 'PT-Login-component';
  isSignInLoaded: boolean = false;
  hasAuth: boolean = false;
  isFetching: boolean = false;
  errors: string = '';
  renPlan: boolean = false;
  showForgotPassword: boolean = false;
  usersName: string = '';
  checkForPlan: any;
  isFirstTimeLogin = false;
  getUserAuths$: Observable<IUserAuthObj[]> = this.lux.get(getTotalUserAuths, this.luxID);

  constructor(
    private appGeneralAsync: PTAppGeneralAsync,
    private commonUserService: CommonUserService,
    private config: AppConfig,
    private oktaAuth: OktaAuth,
    @Inject(DOCUMENT) private docRef: Document,
    private router: Router,
    private ptAlertsAsync: AlertsAsync,
    private lux: Lux<{ appGeneralState: IPTAppGeneralState }>

  ) {
    this.showForgotPassword = this.config.showForgotPassword;
    this.isSignInLoaded = this.config.secureAuth.use;
    if (this.config.secureAuth.use) {
      this.errors = sessionStorage.getItem('loginStatus') === 'FAILED' ? 'Please, check your username and password and try again' : '';
    }
  }

  ngOnInit() {
    this.checkForPlan = this.config.plansAllowed;
    const planFromUrl = this.docRef.location.pathname.split('/')[1];
    this.renPlan = planFromUrl === 'ren';
    from(commonUtil.authentication.status(this.oktaAuth)).subscribe(() => {
      const adminPlan = this.config.plansAllowed[planFromUrl];
      this.usersName = this.commonUserService.getFullName();
      sessionStorage.setItem('userName', this.usersName);
      this.router.navigateByUrl('/oidc-callback');
    });

  }

  firstTimeLogin() {
    this.isFirstTimeLogin = true;
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxID);
  }

}
