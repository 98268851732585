import { IPageIndex } from "../user-admin/state";

export const initialSupportingDocumentsState: ISupportingDocumentsState = {
  supportingDocuments: {
    request: {
      searchCriteria: {
        adminPayer: '',
        payers: [],
        asOfDate: '',
        documentApprovalStatus: 'APPROVED'
      },
      pagination: {
        offset:0,
        limit:100
      },
      sort: {
        fieldName: '',
        order: ''
      }
    },
    response: {
      pagination: {
        totalRecords: 0,
        limit: 0,
        offset: 0
      },
      documentsApproval: []
    },
    errors: '',
    isFetching: false,
    pageIndexTracker: {
      currentPageIndex: 0,
      previousPageIndex: 0
    }
  },
  contractDocumentsState: {
    response: {
      totalRecords: 0,
      contractDocuments: []
    },
    errors: '',
    isFetching: false
  },
  associatedSubgroups: {
    response: {
      groupInfo: {
        clientType: {
          code: '',
          value: ''
        },
        id: 0,
        name: '',
        planAcronym: '',
        routeCondition: '',
        specifiedId: '',
        status: {
          code: '',
          effectiveDate: '',
          originalEffectiveDate: '',
          value: ''
        }
      },
      associatedSubgroups: []
    },
    errors: '',
    isFetching: false
  },
  isFetchingDocument: false,
  errorFetchingDocument: ''
}

export interface ISupportingDocumentsState {
  supportingDocuments: ISupportingDocumentsSearchState;
  contractDocumentsState: IContractDocumentsState;
  associatedSubgroups: IContractDetails;
  isFetchingDocument: boolean;
  errorFetchingDocument: string;
}

export interface IContractDetails {
  response: IContractResponse;
  errors: string;
  isFetching: boolean;
}

export interface IContractResponse {
  groupInfo: IGroupInfo;
  associatedSubgroups: IAssociatedSubgroupDetails[]
}

export interface IGroupInfo {
  clientType: {
    code: string;
    value: string;
  };
  id: number;
  name: string;
  planAcronym: string;
  routeCondition:string;
  specifiedId: string;
  status: {
    code: string;
    effectiveDate: string;
    originalEffectiveDate: string;
    value: string;
  }
}


export interface IAssociatedSubgroupDetails {
  id: number,
  specifiedId:  string,
  name: string,
  planAcronym: string,
  status: {
    code: string;
    effectiveDate: string;
    value: string;
  }
}

export interface IContractDocumentsState {
  response: IContractDocumentsResponse;
  errors: string;
  isFetching: boolean;
}

export interface IContractDocumentsResponse {
  totalRecords: number;
  contractDocuments: IContractDocumentObj[];
}

export interface IContractDocumentObj {
  documentId: string;
  documentName: string;
  documentType: string;
  uploadDate: string;
  uploadApplication: string;
  documentStaus: string;
  statusEffectiveDate: string;
  documentMaskId: string;
  userName: string;
}

export interface ISupportingDocumentsSearchState {
  request: IDocumentsApprovalSearchRequest
  response: IDocumentsApprovalSearchResponse;
  errors: string;
  isFetching: boolean;
  pageIndexTracker: IPageIndex;

}

export interface IDocumentsApprovalSearchRequest {
  searchCriteria: {
    adminPayer: string;
    payers: string [];
    agency?: {
      tins: string[];
    };
    npns?: string[];
    agent?: {
      agentNames: string[];
    };
    asOfDate: string;
    documentApprovalStatus: string;
  },
  pagination: {
    offset: number;
    limit: number;
  },
  sort?: {
    fieldName: string;
    order: string;
  }
}

export interface IDocumentsApprovalSearchResponse {
  pagination: {
    totalRecords: number,
    offset: number;
    limit: number;
  }
  documentsApproval: IDocumentsApprovalObj [];
}

export interface IDocumentsApprovalObj {
  adminPayer: string;
  payer: string;
  agency: {
    tin: string;
    name: string;
  },
  npns: string;
  agent: {
    name: string;
  },
  group: {
    specifiedIdentifier: number;
    name: string;
  },
  contract: {
    id: number;
    startDate: string;
    endDate: string;
  },
  internalContactFullNames: string;
  percentageChange: number;
  agentAgencyId: string;
}

export interface IKeyValue {
  [key: string]: string;
}
