import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportingDocumentsComponent } from '../supporting-documents/supporting-documents.component';
import {TabsModule} from '@roosevelt/common-ui-lib/tabs';
import {TableModule} from '@roosevelt/common-ui-lib/table';
import {PaginatorModule} from '@roosevelt/common-ui-lib/paginator';
import { initialSupportingDocumentsState, ISupportingDocumentsSearchState } from '../store/documents-store/state';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { IconModule } from '@roosevelt/common-ui-lib/icon';
import { NotificationModule } from '@roosevelt/common-ui-lib/notification';
import { ChipModule } from '@roosevelt/common-ui-lib/chip';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';
import { CardModule } from '@roosevelt/common-ui-lib/card';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '@roosevelt/common-ui-lib/select';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';



@NgModule({
  declarations: [
    SupportingDocumentsComponent
  ],
  imports: [
    CommonModule,
    TabsModule,
    TableModule,
    PaginatorModule,
    IconModule,
    NotificationModule,
    ChipModule,
    DialogModule,
    CardModule,
    ReactiveFormsModule,
    SelectModule,
    ButtonModule,
    InputModule
  ]
})
export class SupportingDocumentsModule {
  constructor(lux: Lux<{ supportingDocumentsState: ISupportingDocumentsSearchState }>) {
    const documentsState = JSON.parse(sessionStorage.getItem('documents-state') as any);
    lux.register('supportingDocumentsState', documentsState ? documentsState : initialSupportingDocumentsState);
    lux.allChanges().subscribe(state => {
      sessionStorage.setItem('documents-state', JSON.stringify(state.supportingDocumentsState));
    });
  }
 }
