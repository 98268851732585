import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isUserName, isValidPassword, matchFields } from '../../utils/validations/field-rules';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {

  constructor(private fb: FormBuilder) {
  }

  credentialsForm: FormGroup = this.fb.group({});
  @Output() credentialsSubmit: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.credentialsForm = this.fb.group({
      userIdentifier: ['', [Validators.required, isUserName]],
      password: ['', [isValidPassword, Validators.required]],
      confirmPassword: ['', [matchFields('password', 'confirmPassword', 'mismatch'), Validators.required]]
    });
  }
}
