import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { TabsModule } from '@roosevelt/common-ui-lib/tabs';
import { AuthorizationsComponent } from './authorizations/authorizations.component';
import { TableModule } from '@roosevelt/common-ui-lib/table';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { ChangePasswordModule, MultiFactorModule } from '@roosevelt/authentication-lib';
import { initialProfileState, IProfileState } from '../store/profile/state';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { CheckboxModule } from '@roosevelt/common-ui-lib/checkbox';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';
import { NotificationModule } from '@roosevelt/common-ui-lib/notification';



@NgModule({
  declarations: [
    ProfileComponent,
    AuthorizationsComponent,
    UpdatePasswordComponent
  ],
  imports: [
    CommonModule,
    TabsModule,
    TableModule,
    ButtonModule,
    MultiFactorModule,
    ChangePasswordModule,
    CheckboxModule,
    PaginatorModule,
    NotificationModule
  ]
})
export class ProfileModule {
  constructor(lux: Lux<{ profileState: IProfileState }>) {
    const profileState = JSON.parse(sessionStorage.getItem('profileState') as any);
    lux.register('profileState', profileState ? profileState : initialProfileState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('profileState', JSON.stringify(state.profileState)));
  }
}
