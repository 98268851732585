export const initialUsersListRes: IUserListRes = {
  pagination: {
    limit: 100,
    offset: 0,
    totalRecords: 0
  },
  users: []
}

export const initialGetUsersReq: IUserListReq =  {
  pagination: {
    limit: 100,
    offset: 0,
    totalRecords: 0
  },
  sort: [],
  searchType: ''
}

export const initialUserDetails: IUserDetails = {
  adminPayer: '',
  userStatus: '',
  companyName: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  userIdentifier: '',
  isAccountLocked: false
}

export const initialManageUser: IManageUser = {
  isFetchingUsers: false,
  usersList: initialUsersListRes,
  errorsFetchingUsers: '',
  getUsersReq: initialGetUsersReq,
  pageIndexTracker: {
    currentPageIndex: 0,
    previousPageIndex: 0
  },
  isFetchingUserDetails: false,
  userDetails: initialUserDetails,
  errorFetchingUserDetails: '',
  isUserDetailsUpdating: false,
  errorUpdatingUserDetails: '',
  isUserDetailsUpdated: false
}

export const initialCreateManageUserState: ICreateManageUserState = {
  isUserGettingCreated: false,
  userCreated: false,
  createUserReq: {
    user: {
      userIdentifier:'',
      firstName:'',
      lastName: '',
      emailAddress: '',
      companyName: ''
    }
  },
  errors: '',
  userInfoToViewManageAuth: {
    userId: '',
    userType: ''
  },
  manageUser: initialManageUser
};

export interface ICreateManageUserState {
  isUserGettingCreated: boolean;
  createUserReq: ICreateUserReq;
  errors: string;
  userCreated: boolean;
  userInfoToViewManageAuth: {
    userId: string;
    userType: string;
  };
  manageUser: IManageUser
}

export interface ICreateUserReq {
  user: {
    userIdentifier:string;
    firstName:string;
    lastName:string;
    emailAddress:string;
    companyName:string;
  }
}

export interface IManageUser {
  isFetchingUsers: boolean;
  errorsFetchingUsers: string;
  getUsersReq: IUserListReq;
  pageIndexTracker: IPageIndex;
  usersList: IUserListRes;
  userDetails: IUserDetails;
  isFetchingUserDetails: boolean;
  errorFetchingUserDetails: string;
  isUserDetailsUpdating: boolean;
  errorUpdatingUserDetails: string;
  isUserDetailsUpdated: boolean;
}

export interface IUserListRes {
  pagination: IPagination;
  users: IUserListObj[];
}

export interface IPagination {
  offset: number;
  limit: number;
  totalRecords: number;
}

export interface IUserListObj {
  userName: string;
  userStatus: string;
  userType: string;
  firstName ?: string;
  lastName ?: string;
  roleName: string;
  emailAddress: string;
}

export interface IUserListReq {
  pagination: IPagination;
  sort: ISort[];
  searchType: string;
}

export interface ISort {
  order: string;
  fieldName: string;
}

export interface IPageIndex {
  currentPageIndex: number;
  previousPageIndex: number
}

export interface IUserDetails {
  adminPayer: string;
  userStatus: string;
  userIdentifier: string;
  companyName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  isAccountLocked: boolean;
}
