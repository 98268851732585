
<rs-tab-group color="primary">
    <rs-tab-group-title>
        <div>
            <a [routerLink]="['/producers']" class="rs-backbtn" color="primary" ><< Back To Results</a>
            <h1 class="mt-4">Book of Business</h1>
        </div>
    </rs-tab-group-title>
    <rs-tab label="Group Book of Business" class="p-5 font-weight-bold">
      <pt-lib-group-book-of-business-tab [underWritingPayers]="underWritingPayers"></pt-lib-group-book-of-business-tab>
    </rs-tab>
    <rs-tab label="Individual Book of Business" class="p-5 font-weight-bold">
      <pt-lib-individual-book-of-business-tab [underWritingPayers]="underWritingPayers"></pt-lib-individual-book-of-business-tab>
    </rs-tab>
</rs-tab-group>