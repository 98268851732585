import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../shared/app.config';
import { CommonUserService, Lux } from '@roosevelt/common-ui-lib/core';
import { IAuthInfo, ILoginState, ISolutionsState, loginInitialState } from '../login/store/state';
import { DOCUMENT } from '@angular/common';
import { PTAppGeneralAsync } from '../store/appGeneral/async';
import { getAuthorizedNavigationLinks } from '../utils/navigation-links';
import {OktaAuth} from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { getPayers, getTotalUserAuths } from '../store/appGeneral/selector';
import { IUserAuthObj } from '../store/viewManageUserAuths/state';
import { IPTAppGeneralState } from '../store/appGeneral/state';
import { AlertsAsync } from '../store/alerts/async';
import { IPTEntityAlertsRequest, IPTGeneralAlertsRequest } from '../store/alerts/state';

@Component({
  selector: 'app-oidc-callback',
  templateUrl: './oidc-callback.component.html',
  styleUrls: ['./oidc-callback.component.scss']
})
export class OidcCallbackComponent implements OnInit {
  luxId = 'PT-oidcComponent' + new Date().toISOString();
  checkForPlan: any;
  userId: string = '';
  isUserBeingLoggedIn: boolean = false;
  userRoleError = '';
  postLogoutRedirectUrl: string = '';
  authInfo: IAuthInfo = loginInitialState.authInfo;
  impersonationUser: string = '';
  tokenInfo: any;
  loggedInUserRole: any;
  getUserAuths$: Observable<IUserAuthObj[]> = this.lux.get(getTotalUserAuths, this.luxId);
  usersName: string = '';

  constructor(
    private lux: Lux<{
      login: ILoginState,
      solutionsLogin: ISolutionsState,
      appGeneralState: IPTAppGeneralState
    }>,
    private router: Router,
    private env: AppConfig,
    private commonUserService: CommonUserService,
    @Inject(DOCUMENT) private docRef: Document,
    private oktaAuth: OktaAuth,
    private appGeneralAsync: PTAppGeneralAsync,
    private ptAlertsAsync: AlertsAsync

  ) {}

  ngOnInit(): void {
    // this.isUserBeingLoggedIn = true;
    this.checkForPlan = this.env.plansAllowed;
    let hasIdToken = false;
    const hashParts = location.hash.substring(1)
      .split('&')
      .map(part => part.split(/=(.+)/));

    for (const param of hashParts) {
      sessionStorage.setItem(param[0], param[1]);
      if (param[0].indexOf('id_token') !== -1 || param[0].indexOf('access_token') !== -1) {
        hasIdToken = true;
      }
      if (param[0].indexOf('for_user') !== -1) {
        this.impersonationUser = param[1];
      }
    }
    let acc_token = '';
    const oktaSession = sessionStorage.getItem('okta-token-storage') as any;
    if (oktaSession?.indexOf('idToken') > -1 || oktaSession?.indexOf('accessToken') > -1) {
      hasIdToken = true;
      acc_token = JSON.parse(oktaSession).accessToken.accessToken;
    }

    if (oktaSession?.indexOf('accessToken') > -1) {
      acc_token = JSON.parse(oktaSession).accessToken.accessToken;
    }
    const idToken = sessionStorage.getItem('impersonate_id_token') || sessionStorage.getItem('impersonate_access_token') || acc_token || sessionStorage.getItem('id_token') as string;
    this.tokenInfo = JSON.parse(atob(idToken.split('.')[1]));

    if (!hasIdToken) {
      this.lux.set(state => state.solutionsLogin, {
        isLoginFailed: true
      });
      sessionStorage.clear();
      this.router.navigate(['/login']);
      throw Error();
    }
    // this.isFetchingContext$.subscribe(progress => this.isFetchingContext = progress);
    this.login();
  }

  login() {
    // if (this.env.secureAuth.use) {
    //   this.loginAsync.getAuthInfo().subscribe((response) => {
    //     sessionStorage.setItem('auth-user-info', JSON.stringify(response.body));
    //     this.userId = response.body.id;
    //     sessionStorage.setItem('csrf', response.headers.get('X_CSRF_TOKEN'));
    //     localStorage.setItem('BMT-csrf', response.headers.get('X_CSRF_TOKEN'));
    //   });
    // }
    this.authInfo = {
      email: this.commonUserService.claims?.email || this.tokenInfo?.email,
      firstName: this.commonUserService.claims?.firstName,
      lastName: this.commonUserService.claims?.lastName,
      id: this.commonUserService.claims?.id || this.tokenInfo?.sub,
      expirationTime: this.tokenInfo?.exp,
      cid: this.tokenInfo?.cid,
      impersonationUser: this.impersonationUser || ''
    };
    sessionStorage.setItem('auth-user-info', JSON.stringify(this.authInfo));
    this.lux.set(state => state.login, { authInfo: this.authInfo });
    this.userId = this.commonUserService.claims?.id || this.tokenInfo.sub;
    this.getTotheApp();
  }

  getTotheApp() {
    this.checkForPlan = this.env.plansAllowed;
    const planFromUrl = this.docRef.location.pathname.split('/')[1];
    const adminPlan = this.env.plansAllowed[planFromUrl];
    this.usersName = this.commonUserService && this.commonUserService.claims ? (this.commonUserService.claims as any).name : '';
    if(this.docRef.body.querySelector('.bmt-user-profile .bmt-user-name') as HTMLElement) {
      (this.docRef.body.querySelector('.bmt-user-profile .bmt-user-name') as HTMLElement).innerText = this.usersName;
    }
    this.appGeneralAsync.getLoggedInUserContext(3, adminPlan).subscribe(data => {
      this.appGeneralAsync.getAuthorizations('relaxed', adminPlan).subscribe((auth:any) => {
        let configuredUnderWritingPayers = auth.config && auth.config[adminPlan] ? auth.config[adminPlan] : []
        this.appGeneralAsync.getTotalUserAuths({ pagination: {offset: 0}}).subscribe(() => {
          this.appGeneralAsync.getUserRoles().subscribe(() => {
            this.appGeneralAsync.getPayers(adminPlan, configuredUnderWritingPayers).subscribe(() => {
              const availableRoutes = getAuthorizedNavigationLinks(auth.leftnav as string[]);             
              this.router.navigate(['/' + (availableRoutes?.length ? availableRoutes[0]?.route: 'notAuthorized')]);
            });
          });
        })
      });
    });

    this.getUserAuths$.subscribe(auths => {
      if(auths.length > 0) {
        const DOCUMENT_AUTHORIZED = auths.filter(auth => auth.invokingUserAuthorizations.documentsInd);
        const TINs = DOCUMENT_AUTHORIZED.map(auth => auth.tin).filter(auth => auth?.trim().length > 0);
        const NPNs = DOCUMENT_AUTHORIZED.map(auth => auth.npn).filter(auth => auth?.trim().length > 0);
        const alertsPageSize = 3;
        const alertsPageOffset = 0;
        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        const PTAlertsReq: IPTEntityAlertsRequest = {
          applicationTypeKey: 'PT',
          adminPlanAcronyms: [this.checkForPlan[planFromUrl]],
          onlyActiveAlerts: true,
          recipient: {
            allUsers: true,
            userNames: [this.userId],
          },
          entityList: {
            agentNpnIdentifiers: NPNs,
            agencyTinIdentifiers: TINs,
            planAcronyms: []
          },
          priorityList: [],
          userName: this.userId
        };
        console.log("users name: ", this.usersName, " ID: ", this.userId)
        this.ptAlertsAsync.getPTEntityAlerts(PTAlertsReq, alertsPageSize, alertsPageOffset).subscribe();

        const PTGenAlertsReq: IPTGeneralAlertsRequest = {
          applicationTypeKey: 'PT',
          adminPlanAcronyms: [this.checkForPlan[planFromUrl]],
          priorityList: [],
          onlyActiveAlerts: true,
          recipient: {
            memberPlanAcronyms: [this.checkForPlan[planFromUrl]],
            allUsers: true
          },
          userName: this.userId
        }
        this.ptAlertsAsync.getPTGeneralAlerts(PTGenAlertsReq, alertsPageSize, alertsPageOffset).subscribe();
      }
    });
  }

  // getSessionId() {
  //   const session = sessionStorage.getItem('sessionId');
  //   if (!session) {
  //     this.loginApiService.getSessionId().subscribe((res: any) => {
  //       let errorMessage;
  //       if (res instanceof HttpErrorResponse) {
  //         errorMessage = (res &&
  //           res.error &&
  //           res.error.apiErrorList &&
  //           res.error.apiErrorList.length > 0) && (errorMessage = res.error.apiErrorList.map(item => item.errorMessage).join('\n'));
  //         this.noAuthorizations(false, errorMessage);
  //       } else if (res instanceof TimeoutError) {
  //         this.noAuthorizations(false, 'Session Id API timed out');
  //       } else {
  //         sessionStorage.setItem('sessionId', res);
  //       }
  //     });
  //   }
  // }

}
