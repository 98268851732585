import { Component, Inject, Input, OnInit } from '@angular/core';
import { IAdministrators } from '../../store/registration/state';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input() userInfo: IAdministrators = undefined as any;
  link: string = '';

  constructor(@Inject(DOCUMENT) private docRef: Document) {
  }

  ngOnInit(): void {
    this.link = this.docRef.location.origin + '/' + this.docRef.location.pathname.split('/')[1];
  }

}
