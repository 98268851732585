export const initialAlertsState: IAlertsState = {
  alerts: {
    entityAlerts: [],
    pagination: {
      totalRecords: 0,
      offset: 0,
      limit: 0
    }
  },
  isFetchingAlerts: false,
  errors: '',
  request: {
    applicationTypeKey: "",
    adminPlanAcronyms: [],
    recipient: { allUsers: true },
    entityList: {
      agentNpnIdentifiers: [],
      agencyTinIdentifiers: [],
      planAcronyms: []
    },
    priorityList: [],
    onlyActiveAlerts: true,
    userName: ""
  },
  generalAlerts: {
    generalAlerts: [],
    pagination: {
      totalRecords: 0,
      offset: 0,
      limit: 0
    }
  },
  generalRequest: {
    applicationTypeKey: "",
    adminPlanAcronyms: [],
    priorityList: [],
    onlyActiveAlerts: false,
    recipient: {},
    userName: ""
  }
}

export interface IAlertsState {
  alerts: IPTAlerts;
  generalAlerts: IPTGeneralAlerts;
  isFetchingAlerts: boolean;
  errors: string;
  request: IPTEntityAlertsRequest;
  generalRequest: IPTGeneralAlertsRequest;
}

export interface IPTAlerts {
  entityAlerts: IGeneralAlerts[];
  pagination: {
    totalRecords: number,
    offset: number,
    limit: number
  };
}

export interface IPTGeneralAlerts {
  generalAlerts: IGeneralAlerts[];
  pagination: {
    totalRecords: number,
    offset: number,
    limit: number
  };
}

export interface IGeneralAlerts {
  applicationType: ICodeValue;
  updatedBy: string;
  alertType: string;
  statusType: ICodeValue;
  subject: string;
  alertEntityType: {
      types: ICodeValue[]
  };
  usedForBenefitsInd: boolean;
  updatedOn: string;
  message: string;
  priorityType: ICodeValue;
  createdOn: string;
  adminPlanAcronym: string;
  recipientType: {
      type: ICodeValue;
  };
  createdBy: string;
  alertId: number,
  startDate: string;
}

export interface ICodeValue {
  code: string;
  value: string;
}

export interface IPTEntityAlertsRequest {
  applicationTypeKey: string,
  adminPlanAcronyms: string[],
  priorityList: [],
  onlyActiveAlerts: boolean,
  recipient: {},
  entityList: {
    planAcronyms: string[],
    agentNpnIdentifiers: string[],
    agencyTinIdentifiers: string[]
  },
  userName: string
}

export interface IPTGeneralAlertsRequest {
  applicationTypeKey: string,
  adminPlanAcronyms: string[],
  priorityList: [],
  onlyActiveAlerts: boolean,
  recipient: {},
  userName: string
}

