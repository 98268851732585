import { IKeyNavValue } from "../store/appGeneral/state";

export const DEFAULT_NAVIGATION_LINKS: IKeyNavValue = {
  commissions: {route: '/commissions', imgSrc: 'money_document', text: 'Commissions Statements', position: 0},
  documents: {route: '/documents', imgSrc: 'document', text: 'Supporting Documents', position: 1},
  producers: {route: '/producers', imgSrc: 'report_page', text: 'Producer', position: 2},
  userAdmin: {route: '/userAdmin/create', imgSrc: 'user_group_alt', text: 'User Admin', position: 3},
  profile: {route: '/profile', imgSrc: 'user_outlines', text: 'Profile', position: 4},
  help: {route: '/help', imgSrc: 'support', text: 'Help', position: 5}
};

export const getAuthorizedNavigationLinks = (auth: string[]) => (auth?.length ? auth: []).map(autho => DEFAULT_NAVIGATION_LINKS[autho]).sort(function(a, b) {
  return a.position < b.position ? -1 : 1;
});
