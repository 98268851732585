<rs-tab-group class="supporting-doc-search-container" [selectedIndex]="currentTabIndex">
  <rs-tab-group-title>
    <h3>Profile</h3>
  </rs-tab-group-title>
  <rs-tab label="Authorization" style="padding: 0 30px;" (selected)="tabSelecetd($event)">
    <pt-authorizations></pt-authorizations>
  </rs-tab>
  <rs-tab label="Password Management" style="padding: 0 30px;" (selected)="tabSelecetd($event)">
    <pt-update-password></pt-update-password>
  </rs-tab>
  <rs-tab label="Login Authentication" *ngIf="enableMfa" style="padding: 0 30px;" (selected)="tabSelecetd($event)">
    <lib-multi-factor [hideLoader]="'true'" [multifactorLabels]="getMfaLabels()" [phoneLabels]="getPhoneLabels()"></lib-multi-factor>
  </rs-tab>
</rs-tab-group>
`
